/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createOnboard = /* GraphQL */ `mutation CreateOnboard(
  $input: CreateOnboardInput!
  $condition: ModelOnboardConditionInput
) {
  createOnboard(input: $input, condition: $condition) {
    id
    status
    invitationCode
    formData {
      informationCertification
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    rebatePercentage
    isConstructionType
    isProcoreClient
    isAgaveClient
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOnboardMutationVariables,
  APITypes.CreateOnboardMutation
>;
export const updateOnboard = /* GraphQL */ `mutation UpdateOnboard(
  $input: UpdateOnboardInput!
  $condition: ModelOnboardConditionInput
) {
  updateOnboard(input: $input, condition: $condition) {
    id
    status
    invitationCode
    formData {
      informationCertification
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    rebatePercentage
    isConstructionType
    isProcoreClient
    isAgaveClient
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOnboardMutationVariables,
  APITypes.UpdateOnboardMutation
>;
export const deleteOnboard = /* GraphQL */ `mutation DeleteOnboard(
  $input: DeleteOnboardInput!
  $condition: ModelOnboardConditionInput
) {
  deleteOnboard(input: $input, condition: $condition) {
    id
    status
    invitationCode
    formData {
      informationCertification
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    rebatePercentage
    isConstructionType
    isProcoreClient
    isAgaveClient
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOnboardMutationVariables,
  APITypes.DeleteOnboardMutation
>;
export const createPaidolTheme = /* GraphQL */ `mutation CreatePaidolTheme(
  $input: CreatePaidolThemeInput!
  $condition: ModelPaidolThemeConditionInput
) {
  createPaidolTheme(input: $input, condition: $condition) {
    id
    paidolId
    palette {
      primary
      primaryDark
      primaryDarker
      primaryLight
      primaryLighter
      secondary
      secondaryDark
      textPrimary
      textSecondary
      whiteColor
      successColor
      warningColor
      errorColor
      errorLightColor
      lightestGrayColor
      lightGrayColor
      lightBlueColor
      info
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaidolThemeMutationVariables,
  APITypes.CreatePaidolThemeMutation
>;
export const updatePaidolTheme = /* GraphQL */ `mutation UpdatePaidolTheme(
  $input: UpdatePaidolThemeInput!
  $condition: ModelPaidolThemeConditionInput
) {
  updatePaidolTheme(input: $input, condition: $condition) {
    id
    paidolId
    palette {
      primary
      primaryDark
      primaryDarker
      primaryLight
      primaryLighter
      secondary
      secondaryDark
      textPrimary
      textSecondary
      whiteColor
      successColor
      warningColor
      errorColor
      errorLightColor
      lightestGrayColor
      lightGrayColor
      lightBlueColor
      info
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaidolThemeMutationVariables,
  APITypes.UpdatePaidolThemeMutation
>;
export const deletePaidolTheme = /* GraphQL */ `mutation DeletePaidolTheme(
  $input: DeletePaidolThemeInput!
  $condition: ModelPaidolThemeConditionInput
) {
  deletePaidolTheme(input: $input, condition: $condition) {
    id
    paidolId
    palette {
      primary
      primaryDark
      primaryDarker
      primaryLight
      primaryLighter
      secondary
      secondaryDark
      textPrimary
      textSecondary
      whiteColor
      successColor
      warningColor
      errorColor
      errorLightColor
      lightestGrayColor
      lightGrayColor
      lightBlueColor
      info
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaidolThemeMutationVariables,
  APITypes.DeletePaidolThemeMutation
>;
export const createPaidolUser = /* GraphQL */ `mutation CreatePaidolUser(
  $input: CreatePaidolUserInput!
  $condition: ModelPaidolUserConditionInput
) {
  createPaidolUser(input: $input, condition: $condition) {
    id
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    position
    phone
    address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    roles
    is_test_fixture
    paymentCards {
      nextToken
      __typename
    }
    authorizedUserId
    authorizedUserApplicationId
    allowedJobCodes
    employee_id
    managedGroups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaidolUserMutationVariables,
  APITypes.CreatePaidolUserMutation
>;
export const updatePaidolUser = /* GraphQL */ `mutation UpdatePaidolUser(
  $input: UpdatePaidolUserInput!
  $condition: ModelPaidolUserConditionInput
) {
  updatePaidolUser(input: $input, condition: $condition) {
    id
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    position
    phone
    address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    roles
    is_test_fixture
    paymentCards {
      nextToken
      __typename
    }
    authorizedUserId
    authorizedUserApplicationId
    allowedJobCodes
    employee_id
    managedGroups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaidolUserMutationVariables,
  APITypes.UpdatePaidolUserMutation
>;
export const deletePaidolUser = /* GraphQL */ `mutation DeletePaidolUser(
  $input: DeletePaidolUserInput!
  $condition: ModelPaidolUserConditionInput
) {
  deletePaidolUser(input: $input, condition: $condition) {
    id
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    position
    phone
    address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    roles
    is_test_fixture
    paymentCards {
      nextToken
      __typename
    }
    authorizedUserId
    authorizedUserApplicationId
    allowedJobCodes
    employee_id
    managedGroups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaidolUserMutationVariables,
  APITypes.DeletePaidolUserMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    username
    email
    first_name
    last_name
    paidol_users {
      nextToken
      __typename
    }
    picture
    is_speedster
    birthdate
    address
    phone_number
    ssn
    phone_extension
    password_updated_at
    finicityCustomerId
    dwollaCustomerId
    firstRunBanner {
      insights
      transactions
      reimbursements
      cardsManagement
      spendRules
      funding
      teamMembers
      groups
      wallet
      settings
      connections
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    username
    email
    first_name
    last_name
    paidol_users {
      nextToken
      __typename
    }
    picture
    is_speedster
    birthdate
    address
    phone_number
    ssn
    phone_extension
    password_updated_at
    finicityCustomerId
    dwollaCustomerId
    firstRunBanner {
      insights
      transactions
      reimbursements
      cardsManagement
      spendRules
      funding
      teamMembers
      groups
      wallet
      settings
      connections
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    username
    email
    first_name
    last_name
    paidol_users {
      nextToken
      __typename
    }
    picture
    is_speedster
    birthdate
    address
    phone_number
    ssn
    phone_extension
    password_updated_at
    finicityCustomerId
    dwollaCustomerId
    firstRunBanner {
      insights
      transactions
      reimbursements
      cardsManagement
      spendRules
      funding
      teamMembers
      groups
      wallet
      settings
      connections
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createPaidol = /* GraphQL */ `mutation CreatePaidol(
  $input: CreatePaidolInput!
  $condition: ModelPaidolConditionInput
) {
  createPaidol(input: $input, condition: $condition) {
    id
    id_base58
    id_base58_short
    owner_paidol_id
    slug
    legal_name
    account_balance
    name
    dba
    ein
    mcc
    corp_type
    industry
    company_email
    customer_facing_email
    company_phone
    website
    company_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    customer_facing_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    legal_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    business_owner_ssn
    dwolla_business_industry_classification
    admin_user_id
    accounts_payable_user_id
    accounts_receivable_user_id
    payment_methods {
      ach
      vcard
      check
      __typename
    }
    low_balance_alert_threshold
    low_balance_auto_funding_amount
    low_balance_auto_funding_threshold
    speedscore
    priority_status
    priority_buyer_id
    deleted_at
    company_id
    logo
    priority_response
    type
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardRequested
    virtualCardEnabled
    achRequested
    cron_status
    owner_sub
    is_test_fixture
    is_migration
    enablePayables
    enablePCards
    reimbursementSettings {
      isEnabled
      isCheckrunEnabled
      rhythm
      day
      weekday
      ordinal
      __typename
    }
    jobCodeSettings {
      isEnabled
      __typename
    }
    flexCodingSettings {
      isEnabled
      __typename
    }
    isConstructionType
    agaveAccountToken
    isAgaveClient
    selectedERP
    primaryAuthorizedPerson {
      firstName
      lastName
      email
      hasExtension
      birthdate
      socialSecurityNumber
      jobTitle
      percentageOwnership
      __typename
    }
    firstRunChecklist {
      connectFinancialAccount
      addAdmin
      addTeamMember
      createSpendRule
      issueCard
      createGroup
      setReimbursement
      integrateERP
      dismissWelcomePage
      isStepperComplete
      __typename
    }
    logoUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaidolMutationVariables,
  APITypes.CreatePaidolMutation
>;
export const updatePaidol = /* GraphQL */ `mutation UpdatePaidol(
  $input: UpdatePaidolInput!
  $condition: ModelPaidolConditionInput
) {
  updatePaidol(input: $input, condition: $condition) {
    id
    id_base58
    id_base58_short
    owner_paidol_id
    slug
    legal_name
    account_balance
    name
    dba
    ein
    mcc
    corp_type
    industry
    company_email
    customer_facing_email
    company_phone
    website
    company_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    customer_facing_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    legal_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    business_owner_ssn
    dwolla_business_industry_classification
    admin_user_id
    accounts_payable_user_id
    accounts_receivable_user_id
    payment_methods {
      ach
      vcard
      check
      __typename
    }
    low_balance_alert_threshold
    low_balance_auto_funding_amount
    low_balance_auto_funding_threshold
    speedscore
    priority_status
    priority_buyer_id
    deleted_at
    company_id
    logo
    priority_response
    type
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardRequested
    virtualCardEnabled
    achRequested
    cron_status
    owner_sub
    is_test_fixture
    is_migration
    enablePayables
    enablePCards
    reimbursementSettings {
      isEnabled
      isCheckrunEnabled
      rhythm
      day
      weekday
      ordinal
      __typename
    }
    jobCodeSettings {
      isEnabled
      __typename
    }
    flexCodingSettings {
      isEnabled
      __typename
    }
    isConstructionType
    agaveAccountToken
    isAgaveClient
    selectedERP
    primaryAuthorizedPerson {
      firstName
      lastName
      email
      hasExtension
      birthdate
      socialSecurityNumber
      jobTitle
      percentageOwnership
      __typename
    }
    firstRunChecklist {
      connectFinancialAccount
      addAdmin
      addTeamMember
      createSpendRule
      issueCard
      createGroup
      setReimbursement
      integrateERP
      dismissWelcomePage
      isStepperComplete
      __typename
    }
    logoUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaidolMutationVariables,
  APITypes.UpdatePaidolMutation
>;
export const deletePaidol = /* GraphQL */ `mutation DeletePaidol(
  $input: DeletePaidolInput!
  $condition: ModelPaidolConditionInput
) {
  deletePaidol(input: $input, condition: $condition) {
    id
    id_base58
    id_base58_short
    owner_paidol_id
    slug
    legal_name
    account_balance
    name
    dba
    ein
    mcc
    corp_type
    industry
    company_email
    customer_facing_email
    company_phone
    website
    company_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    customer_facing_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    legal_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    business_owner_ssn
    dwolla_business_industry_classification
    admin_user_id
    accounts_payable_user_id
    accounts_receivable_user_id
    payment_methods {
      ach
      vcard
      check
      __typename
    }
    low_balance_alert_threshold
    low_balance_auto_funding_amount
    low_balance_auto_funding_threshold
    speedscore
    priority_status
    priority_buyer_id
    deleted_at
    company_id
    logo
    priority_response
    type
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardRequested
    virtualCardEnabled
    achRequested
    cron_status
    owner_sub
    is_test_fixture
    is_migration
    enablePayables
    enablePCards
    reimbursementSettings {
      isEnabled
      isCheckrunEnabled
      rhythm
      day
      weekday
      ordinal
      __typename
    }
    jobCodeSettings {
      isEnabled
      __typename
    }
    flexCodingSettings {
      isEnabled
      __typename
    }
    isConstructionType
    agaveAccountToken
    isAgaveClient
    selectedERP
    primaryAuthorizedPerson {
      firstName
      lastName
      email
      hasExtension
      birthdate
      socialSecurityNumber
      jobTitle
      percentageOwnership
      __typename
    }
    firstRunChecklist {
      connectFinancialAccount
      addAdmin
      addTeamMember
      createSpendRule
      issueCard
      createGroup
      setReimbursement
      integrateERP
      dismissWelcomePage
      isStepperComplete
      __typename
    }
    logoUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaidolMutationVariables,
  APITypes.DeletePaidolMutation
>;
export const createPaymentFileQueue = /* GraphQL */ `mutation CreatePaymentFileQueue(
  $input: CreatePaymentFileQueueInput!
  $condition: ModelPaymentFileQueueConditionInput
) {
  createPaymentFileQueue(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_payments
    num_success
    num_failed
    total {
      value
      currency
      __typename
    }
    createdAt
    filename
    supplier_vendor_paidol_ids
    referenced_vendors
    invoice_ids
    invoice_spx_ids
    priority_status
    priority_file_id
    priority_process_on
    priority_response
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentFileQueueMutationVariables,
  APITypes.CreatePaymentFileQueueMutation
>;
export const updatePaymentFileQueue = /* GraphQL */ `mutation UpdatePaymentFileQueue(
  $input: UpdatePaymentFileQueueInput!
  $condition: ModelPaymentFileQueueConditionInput
) {
  updatePaymentFileQueue(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_payments
    num_success
    num_failed
    total {
      value
      currency
      __typename
    }
    createdAt
    filename
    supplier_vendor_paidol_ids
    referenced_vendors
    invoice_ids
    invoice_spx_ids
    priority_status
    priority_file_id
    priority_process_on
    priority_response
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentFileQueueMutationVariables,
  APITypes.UpdatePaymentFileQueueMutation
>;
export const deletePaymentFileQueue = /* GraphQL */ `mutation DeletePaymentFileQueue(
  $input: DeletePaymentFileQueueInput!
  $condition: ModelPaymentFileQueueConditionInput
) {
  deletePaymentFileQueue(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_payments
    num_success
    num_failed
    total {
      value
      currency
      __typename
    }
    createdAt
    filename
    supplier_vendor_paidol_ids
    referenced_vendors
    invoice_ids
    invoice_spx_ids
    priority_status
    priority_file_id
    priority_process_on
    priority_response
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentFileQueueMutationVariables,
  APITypes.DeletePaymentFileQueueMutation
>;
export const createPaidolVendor = /* GraphQL */ `mutation CreatePaidolVendor(
  $input: CreatePaidolVendorInput!
  $condition: ModelPaidolVendorConditionInput
) {
  createPaidolVendor(input: $input, condition: $condition) {
    id
    user_vendor_id
    is_migration
    paidol_id
    vendor {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    vendor_paidol_id
    priority_active
    payment_terms
    priority_account_balance
    contact_name
    priority_billing_code
    priority_repeate_code
    priority_group_code
    email_addresses
    priority_document_delivery
    priority_regular_inv
    priority_summary_inv
    foreign_name
    remarks
    box_1099
    form_code_1099
    remark_1
    priority_response
    cron_status
    priority_status
    priority_supplier_id
    virtual_card_email
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardEnabled
    checkIssuingEnabled
    checkIssuingPayableTo
    checkIssuingStreet
    checkIssuingCity
    checkIssuingState
    checkIssuingCountry
    checkIssuingPostalCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaidolVendorMutationVariables,
  APITypes.CreatePaidolVendorMutation
>;
export const updatePaidolVendor = /* GraphQL */ `mutation UpdatePaidolVendor(
  $input: UpdatePaidolVendorInput!
  $condition: ModelPaidolVendorConditionInput
) {
  updatePaidolVendor(input: $input, condition: $condition) {
    id
    user_vendor_id
    is_migration
    paidol_id
    vendor {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    vendor_paidol_id
    priority_active
    payment_terms
    priority_account_balance
    contact_name
    priority_billing_code
    priority_repeate_code
    priority_group_code
    email_addresses
    priority_document_delivery
    priority_regular_inv
    priority_summary_inv
    foreign_name
    remarks
    box_1099
    form_code_1099
    remark_1
    priority_response
    cron_status
    priority_status
    priority_supplier_id
    virtual_card_email
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardEnabled
    checkIssuingEnabled
    checkIssuingPayableTo
    checkIssuingStreet
    checkIssuingCity
    checkIssuingState
    checkIssuingCountry
    checkIssuingPostalCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaidolVendorMutationVariables,
  APITypes.UpdatePaidolVendorMutation
>;
export const deletePaidolVendor = /* GraphQL */ `mutation DeletePaidolVendor(
  $input: DeletePaidolVendorInput!
  $condition: ModelPaidolVendorConditionInput
) {
  deletePaidolVendor(input: $input, condition: $condition) {
    id
    user_vendor_id
    is_migration
    paidol_id
    vendor {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    vendor_paidol_id
    priority_active
    payment_terms
    priority_account_balance
    contact_name
    priority_billing_code
    priority_repeate_code
    priority_group_code
    email_addresses
    priority_document_delivery
    priority_regular_inv
    priority_summary_inv
    foreign_name
    remarks
    box_1099
    form_code_1099
    remark_1
    priority_response
    cron_status
    priority_status
    priority_supplier_id
    virtual_card_email
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardEnabled
    checkIssuingEnabled
    checkIssuingPayableTo
    checkIssuingStreet
    checkIssuingCity
    checkIssuingState
    checkIssuingCountry
    checkIssuingPostalCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaidolVendorMutationVariables,
  APITypes.DeletePaidolVendorMutation
>;
export const createInvoice = /* GraphQL */ `mutation CreateInvoice(
  $input: CreateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  createInvoice(input: $input, condition: $condition) {
    id
    id_base58
    id_base58_short
    payment_file_queue_id
    payment_file {
      id
      user_id
      paidol_id
      key
      status
      processed_at
      error_messages
      num_vendors
      num_payments
      num_success
      num_failed
      createdAt
      filename
      supplier_vendor_paidol_ids
      referenced_vendors
      invoice_ids
      invoice_spx_ids
      priority_status
      priority_file_id
      priority_process_on
      priority_response
      updatedAt
      __typename
    }
    buyer_paidol_id
    paidol_vendor_id
    paidol_vendor {
      id
      user_vendor_id
      is_migration
      paidol_id
      vendor_paidol_id
      priority_active
      payment_terms
      priority_account_balance
      contact_name
      priority_billing_code
      priority_repeate_code
      priority_group_code
      email_addresses
      priority_document_delivery
      priority_regular_inv
      priority_summary_inv
      foreign_name
      remarks
      box_1099
      form_code_1099
      remark_1
      priority_response
      cron_status
      priority_status
      priority_supplier_id
      virtual_card_email
      achEnabled
      virtualCardEnabled
      checkIssuingEnabled
      checkIssuingPayableTo
      checkIssuingStreet
      checkIssuingCity
      checkIssuingState
      checkIssuingCountry
      checkIssuingPostalCode
      createdAt
      updatedAt
      __typename
    }
    supplier_paidol_id
    supplier_paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    user_invoice_id
    date
    balance_due {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    transaction_id
    priority_payment_id
    priority_payment_status
    priority_payment_type
    priority_payment_date
    priority_payment_auth_message
    priority_response
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvoiceMutationVariables,
  APITypes.CreateInvoiceMutation
>;
export const updateInvoice = /* GraphQL */ `mutation UpdateInvoice(
  $input: UpdateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  updateInvoice(input: $input, condition: $condition) {
    id
    id_base58
    id_base58_short
    payment_file_queue_id
    payment_file {
      id
      user_id
      paidol_id
      key
      status
      processed_at
      error_messages
      num_vendors
      num_payments
      num_success
      num_failed
      createdAt
      filename
      supplier_vendor_paidol_ids
      referenced_vendors
      invoice_ids
      invoice_spx_ids
      priority_status
      priority_file_id
      priority_process_on
      priority_response
      updatedAt
      __typename
    }
    buyer_paidol_id
    paidol_vendor_id
    paidol_vendor {
      id
      user_vendor_id
      is_migration
      paidol_id
      vendor_paidol_id
      priority_active
      payment_terms
      priority_account_balance
      contact_name
      priority_billing_code
      priority_repeate_code
      priority_group_code
      email_addresses
      priority_document_delivery
      priority_regular_inv
      priority_summary_inv
      foreign_name
      remarks
      box_1099
      form_code_1099
      remark_1
      priority_response
      cron_status
      priority_status
      priority_supplier_id
      virtual_card_email
      achEnabled
      virtualCardEnabled
      checkIssuingEnabled
      checkIssuingPayableTo
      checkIssuingStreet
      checkIssuingCity
      checkIssuingState
      checkIssuingCountry
      checkIssuingPostalCode
      createdAt
      updatedAt
      __typename
    }
    supplier_paidol_id
    supplier_paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    user_invoice_id
    date
    balance_due {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    transaction_id
    priority_payment_id
    priority_payment_status
    priority_payment_type
    priority_payment_date
    priority_payment_auth_message
    priority_response
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvoiceMutationVariables,
  APITypes.UpdateInvoiceMutation
>;
export const deleteInvoice = /* GraphQL */ `mutation DeleteInvoice(
  $input: DeleteInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  deleteInvoice(input: $input, condition: $condition) {
    id
    id_base58
    id_base58_short
    payment_file_queue_id
    payment_file {
      id
      user_id
      paidol_id
      key
      status
      processed_at
      error_messages
      num_vendors
      num_payments
      num_success
      num_failed
      createdAt
      filename
      supplier_vendor_paidol_ids
      referenced_vendors
      invoice_ids
      invoice_spx_ids
      priority_status
      priority_file_id
      priority_process_on
      priority_response
      updatedAt
      __typename
    }
    buyer_paidol_id
    paidol_vendor_id
    paidol_vendor {
      id
      user_vendor_id
      is_migration
      paidol_id
      vendor_paidol_id
      priority_active
      payment_terms
      priority_account_balance
      contact_name
      priority_billing_code
      priority_repeate_code
      priority_group_code
      email_addresses
      priority_document_delivery
      priority_regular_inv
      priority_summary_inv
      foreign_name
      remarks
      box_1099
      form_code_1099
      remark_1
      priority_response
      cron_status
      priority_status
      priority_supplier_id
      virtual_card_email
      achEnabled
      virtualCardEnabled
      checkIssuingEnabled
      checkIssuingPayableTo
      checkIssuingStreet
      checkIssuingCity
      checkIssuingState
      checkIssuingCountry
      checkIssuingPostalCode
      createdAt
      updatedAt
      __typename
    }
    supplier_paidol_id
    supplier_paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    user_invoice_id
    date
    balance_due {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    transaction_id
    priority_payment_id
    priority_payment_status
    priority_payment_type
    priority_payment_date
    priority_payment_auth_message
    priority_response
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvoiceMutationVariables,
  APITypes.DeleteInvoiceMutation
>;
export const createSupplierFileQueue = /* GraphQL */ `mutation CreateSupplierFileQueue(
  $input: CreateSupplierFileQueueInput!
  $condition: ModelSupplierFileQueueConditionInput
) {
  createSupplierFileQueue(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_success
    num_failed
    createdAt
    is_migration
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSupplierFileQueueMutationVariables,
  APITypes.CreateSupplierFileQueueMutation
>;
export const updateSupplierFileQueue = /* GraphQL */ `mutation UpdateSupplierFileQueue(
  $input: UpdateSupplierFileQueueInput!
  $condition: ModelSupplierFileQueueConditionInput
) {
  updateSupplierFileQueue(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_success
    num_failed
    createdAt
    is_migration
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSupplierFileQueueMutationVariables,
  APITypes.UpdateSupplierFileQueueMutation
>;
export const deleteSupplierFileQueue = /* GraphQL */ `mutation DeleteSupplierFileQueue(
  $input: DeleteSupplierFileQueueInput!
  $condition: ModelSupplierFileQueueConditionInput
) {
  deleteSupplierFileQueue(input: $input, condition: $condition) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_success
    num_failed
    createdAt
    is_migration
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSupplierFileQueueMutationVariables,
  APITypes.DeleteSupplierFileQueueMutation
>;
export const createPaymentStatusDownload = /* GraphQL */ `mutation CreatePaymentStatusDownload(
  $input: CreatePaymentStatusDownloadInput!
  $condition: ModelPaymentStatusDownloadConditionInput
) {
  createPaymentStatusDownload(input: $input, condition: $condition) {
    id
    user_id
    paidol_id
    start_date
    end_date
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentStatusDownloadMutationVariables,
  APITypes.CreatePaymentStatusDownloadMutation
>;
export const updatePaymentStatusDownload = /* GraphQL */ `mutation UpdatePaymentStatusDownload(
  $input: UpdatePaymentStatusDownloadInput!
  $condition: ModelPaymentStatusDownloadConditionInput
) {
  updatePaymentStatusDownload(input: $input, condition: $condition) {
    id
    user_id
    paidol_id
    start_date
    end_date
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentStatusDownloadMutationVariables,
  APITypes.UpdatePaymentStatusDownloadMutation
>;
export const deletePaymentStatusDownload = /* GraphQL */ `mutation DeletePaymentStatusDownload(
  $input: DeletePaymentStatusDownloadInput!
  $condition: ModelPaymentStatusDownloadConditionInput
) {
  deletePaymentStatusDownload(input: $input, condition: $condition) {
    id
    user_id
    paidol_id
    start_date
    end_date
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentStatusDownloadMutationVariables,
  APITypes.DeletePaymentStatusDownloadMutation
>;
export const createPaidolHighnoteIntegration = /* GraphQL */ `mutation CreatePaidolHighnoteIntegration(
  $input: CreatePaidolHighnoteIntegrationInput!
  $condition: ModelPaidolHighnoteIntegrationConditionInput
) {
  createPaidolHighnoteIntegration(input: $input, condition: $condition) {
    id
    paidolId
    userId
    legalBusinessName
    businessAccountHolderId
    primaryAuthorizedPersonId
    financialAccountId
    externalFinancialBankAccountId
    hasInitialFunding
    accountHolderCardProductApplicationId
    isApplicationAccepted
    isDisconnected
    highnoteProductId
    highnoteProduct {
      id
      type
      isActive
      createdAt
      updatedAt
      __typename
    }
    finicityCustomerId
    dwollaCustomerId
    cardProfileId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaidolHighnoteIntegrationMutationVariables,
  APITypes.CreatePaidolHighnoteIntegrationMutation
>;
export const updatePaidolHighnoteIntegration = /* GraphQL */ `mutation UpdatePaidolHighnoteIntegration(
  $input: UpdatePaidolHighnoteIntegrationInput!
  $condition: ModelPaidolHighnoteIntegrationConditionInput
) {
  updatePaidolHighnoteIntegration(input: $input, condition: $condition) {
    id
    paidolId
    userId
    legalBusinessName
    businessAccountHolderId
    primaryAuthorizedPersonId
    financialAccountId
    externalFinancialBankAccountId
    hasInitialFunding
    accountHolderCardProductApplicationId
    isApplicationAccepted
    isDisconnected
    highnoteProductId
    highnoteProduct {
      id
      type
      isActive
      createdAt
      updatedAt
      __typename
    }
    finicityCustomerId
    dwollaCustomerId
    cardProfileId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaidolHighnoteIntegrationMutationVariables,
  APITypes.UpdatePaidolHighnoteIntegrationMutation
>;
export const deletePaidolHighnoteIntegration = /* GraphQL */ `mutation DeletePaidolHighnoteIntegration(
  $input: DeletePaidolHighnoteIntegrationInput!
  $condition: ModelPaidolHighnoteIntegrationConditionInput
) {
  deletePaidolHighnoteIntegration(input: $input, condition: $condition) {
    id
    paidolId
    userId
    legalBusinessName
    businessAccountHolderId
    primaryAuthorizedPersonId
    financialAccountId
    externalFinancialBankAccountId
    hasInitialFunding
    accountHolderCardProductApplicationId
    isApplicationAccepted
    isDisconnected
    highnoteProductId
    highnoteProduct {
      id
      type
      isActive
      createdAt
      updatedAt
      __typename
    }
    finicityCustomerId
    dwollaCustomerId
    cardProfileId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaidolHighnoteIntegrationMutationVariables,
  APITypes.DeletePaidolHighnoteIntegrationMutation
>;
export const createHighnoteProduct = /* GraphQL */ `mutation CreateHighnoteProduct(
  $input: CreateHighnoteProductInput!
  $condition: ModelHighnoteProductConditionInput
) {
  createHighnoteProduct(input: $input, condition: $condition) {
    id
    type
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHighnoteProductMutationVariables,
  APITypes.CreateHighnoteProductMutation
>;
export const updateHighnoteProduct = /* GraphQL */ `mutation UpdateHighnoteProduct(
  $input: UpdateHighnoteProductInput!
  $condition: ModelHighnoteProductConditionInput
) {
  updateHighnoteProduct(input: $input, condition: $condition) {
    id
    type
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHighnoteProductMutationVariables,
  APITypes.UpdateHighnoteProductMutation
>;
export const deleteHighnoteProduct = /* GraphQL */ `mutation DeleteHighnoteProduct(
  $input: DeleteHighnoteProductInput!
  $condition: ModelHighnoteProductConditionInput
) {
  deleteHighnoteProduct(input: $input, condition: $condition) {
    id
    type
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHighnoteProductMutationVariables,
  APITypes.DeleteHighnoteProductMutation
>;
export const createHighnotePaymentCard = /* GraphQL */ `mutation CreateHighnotePaymentCard(
  $input: CreateHighnotePaymentCardInput!
  $condition: ModelHighnotePaymentCardConditionInput
) {
  createHighnotePaymentCard(input: $input, condition: $condition) {
    paymentCardId
    paidolId
    authPaidolId
    paidolUsers {
      nextToken
      __typename
    }
    name
    cardGroupId
    group {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    status
    bin
    last4
    network
    formFactor
    expirationDate
    budget {
      __typename
    }
    hasPin
    procoreEmployee {
      id
      name
      email_address
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHighnotePaymentCardMutationVariables,
  APITypes.CreateHighnotePaymentCardMutation
>;
export const updateHighnotePaymentCard = /* GraphQL */ `mutation UpdateHighnotePaymentCard(
  $input: UpdateHighnotePaymentCardInput!
  $condition: ModelHighnotePaymentCardConditionInput
) {
  updateHighnotePaymentCard(input: $input, condition: $condition) {
    paymentCardId
    paidolId
    authPaidolId
    paidolUsers {
      nextToken
      __typename
    }
    name
    cardGroupId
    group {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    status
    bin
    last4
    network
    formFactor
    expirationDate
    budget {
      __typename
    }
    hasPin
    procoreEmployee {
      id
      name
      email_address
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHighnotePaymentCardMutationVariables,
  APITypes.UpdateHighnotePaymentCardMutation
>;
export const deleteHighnotePaymentCard = /* GraphQL */ `mutation DeleteHighnotePaymentCard(
  $input: DeleteHighnotePaymentCardInput!
  $condition: ModelHighnotePaymentCardConditionInput
) {
  deleteHighnotePaymentCard(input: $input, condition: $condition) {
    paymentCardId
    paidolId
    authPaidolId
    paidolUsers {
      nextToken
      __typename
    }
    name
    cardGroupId
    group {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    status
    bin
    last4
    network
    formFactor
    expirationDate
    budget {
      __typename
    }
    hasPin
    procoreEmployee {
      id
      name
      email_address
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHighnotePaymentCardMutationVariables,
  APITypes.DeleteHighnotePaymentCardMutation
>;
export const createHighnotePaymentCardSpendRules = /* GraphQL */ `mutation CreateHighnotePaymentCardSpendRules(
  $input: CreateHighnotePaymentCardSpendRulesInput!
  $condition: ModelHighnotePaymentCardSpendRulesConditionInput
) {
  createHighnotePaymentCardSpendRules(input: $input, condition: $condition) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    merchantCategoryAllowed
    merchantCategoryBlocked
    merchantCountryAllowed
    merchantCountryBlocked
    streetAddressAllowed
    streetAddressBlocked
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHighnotePaymentCardSpendRulesMutationVariables,
  APITypes.CreateHighnotePaymentCardSpendRulesMutation
>;
export const updateHighnotePaymentCardSpendRules = /* GraphQL */ `mutation UpdateHighnotePaymentCardSpendRules(
  $input: UpdateHighnotePaymentCardSpendRulesInput!
  $condition: ModelHighnotePaymentCardSpendRulesConditionInput
) {
  updateHighnotePaymentCardSpendRules(input: $input, condition: $condition) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    merchantCategoryAllowed
    merchantCategoryBlocked
    merchantCountryAllowed
    merchantCountryBlocked
    streetAddressAllowed
    streetAddressBlocked
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHighnotePaymentCardSpendRulesMutationVariables,
  APITypes.UpdateHighnotePaymentCardSpendRulesMutation
>;
export const deleteHighnotePaymentCardSpendRules = /* GraphQL */ `mutation DeleteHighnotePaymentCardSpendRules(
  $input: DeleteHighnotePaymentCardSpendRulesInput!
  $condition: ModelHighnotePaymentCardSpendRulesConditionInput
) {
  deleteHighnotePaymentCardSpendRules(input: $input, condition: $condition) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    merchantCategoryAllowed
    merchantCategoryBlocked
    merchantCountryAllowed
    merchantCountryBlocked
    streetAddressAllowed
    streetAddressBlocked
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHighnotePaymentCardSpendRulesMutationVariables,
  APITypes.DeleteHighnotePaymentCardSpendRulesMutation
>;
export const createHighnotePaymentCardVelocityRules = /* GraphQL */ `mutation CreateHighnotePaymentCardVelocityRules(
  $input: CreateHighnotePaymentCardVelocityRulesInput!
  $condition: ModelHighnotePaymentCardVelocityRulesConditionInput
) {
  createHighnotePaymentCardVelocityRules(input: $input, condition: $condition) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    cumulativeRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHighnotePaymentCardVelocityRulesMutationVariables,
  APITypes.CreateHighnotePaymentCardVelocityRulesMutation
>;
export const updateHighnotePaymentCardVelocityRules = /* GraphQL */ `mutation UpdateHighnotePaymentCardVelocityRules(
  $input: UpdateHighnotePaymentCardVelocityRulesInput!
  $condition: ModelHighnotePaymentCardVelocityRulesConditionInput
) {
  updateHighnotePaymentCardVelocityRules(input: $input, condition: $condition) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    cumulativeRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHighnotePaymentCardVelocityRulesMutationVariables,
  APITypes.UpdateHighnotePaymentCardVelocityRulesMutation
>;
export const deleteHighnotePaymentCardVelocityRules = /* GraphQL */ `mutation DeleteHighnotePaymentCardVelocityRules(
  $input: DeleteHighnotePaymentCardVelocityRulesInput!
  $condition: ModelHighnotePaymentCardVelocityRulesConditionInput
) {
  deleteHighnotePaymentCardVelocityRules(input: $input, condition: $condition) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    cumulativeRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHighnotePaymentCardVelocityRulesMutationVariables,
  APITypes.DeleteHighnotePaymentCardVelocityRulesMutation
>;
export const createBudget = /* GraphQL */ `mutation CreateBudget(
  $input: CreateBudgetInput!
  $condition: ModelBudgetConditionInput
) {
  createBudget(input: $input, condition: $condition) {
    paidolId
    authPaidolId
    budget {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBudgetMutationVariables,
  APITypes.CreateBudgetMutation
>;
export const updateBudget = /* GraphQL */ `mutation UpdateBudget(
  $input: UpdateBudgetInput!
  $condition: ModelBudgetConditionInput
) {
  updateBudget(input: $input, condition: $condition) {
    paidolId
    authPaidolId
    budget {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBudgetMutationVariables,
  APITypes.UpdateBudgetMutation
>;
export const deleteBudget = /* GraphQL */ `mutation DeleteBudget(
  $input: DeleteBudgetInput!
  $condition: ModelBudgetConditionInput
) {
  deleteBudget(input: $input, condition: $condition) {
    paidolId
    authPaidolId
    budget {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBudgetMutationVariables,
  APITypes.DeleteBudgetMutation
>;
export const createMcc = /* GraphQL */ `mutation CreateMcc(
  $input: CreateMccInput!
  $condition: ModelMccConditionInput
) {
  createMcc(input: $input, condition: $condition) {
    id
    code
    highnoteDescription
    highnoteCategoryDescription
    speedchainDescription
    expenseTypes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMccMutationVariables,
  APITypes.CreateMccMutation
>;
export const updateMcc = /* GraphQL */ `mutation UpdateMcc(
  $input: UpdateMccInput!
  $condition: ModelMccConditionInput
) {
  updateMcc(input: $input, condition: $condition) {
    id
    code
    highnoteDescription
    highnoteCategoryDescription
    speedchainDescription
    expenseTypes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMccMutationVariables,
  APITypes.UpdateMccMutation
>;
export const deleteMcc = /* GraphQL */ `mutation DeleteMcc(
  $input: DeleteMccInput!
  $condition: ModelMccConditionInput
) {
  deleteMcc(input: $input, condition: $condition) {
    id
    code
    highnoteDescription
    highnoteCategoryDescription
    speedchainDescription
    expenseTypes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMccMutationVariables,
  APITypes.DeleteMccMutation
>;
export const createExpenseType = /* GraphQL */ `mutation CreateExpenseType(
  $input: CreateExpenseTypeInput!
  $condition: ModelExpenseTypeConditionInput
) {
  createExpenseType(input: $input, condition: $condition) {
    id
    name
    codes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExpenseTypeMutationVariables,
  APITypes.CreateExpenseTypeMutation
>;
export const updateExpenseType = /* GraphQL */ `mutation UpdateExpenseType(
  $input: UpdateExpenseTypeInput!
  $condition: ModelExpenseTypeConditionInput
) {
  updateExpenseType(input: $input, condition: $condition) {
    id
    name
    codes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExpenseTypeMutationVariables,
  APITypes.UpdateExpenseTypeMutation
>;
export const deleteExpenseType = /* GraphQL */ `mutation DeleteExpenseType(
  $input: DeleteExpenseTypeInput!
  $condition: ModelExpenseTypeConditionInput
) {
  deleteExpenseType(input: $input, condition: $condition) {
    id
    name
    codes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExpenseTypeMutationVariables,
  APITypes.DeleteExpenseTypeMutation
>;
export const createReceiptSummaryField = /* GraphQL */ `mutation CreateReceiptSummaryField(
  $input: CreateReceiptSummaryFieldInput!
  $condition: ModelReceiptSummaryFieldConditionInput
) {
  createReceiptSummaryField(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReceiptSummaryFieldMutationVariables,
  APITypes.CreateReceiptSummaryFieldMutation
>;
export const updateReceiptSummaryField = /* GraphQL */ `mutation UpdateReceiptSummaryField(
  $input: UpdateReceiptSummaryFieldInput!
  $condition: ModelReceiptSummaryFieldConditionInput
) {
  updateReceiptSummaryField(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReceiptSummaryFieldMutationVariables,
  APITypes.UpdateReceiptSummaryFieldMutation
>;
export const deleteReceiptSummaryField = /* GraphQL */ `mutation DeleteReceiptSummaryField(
  $input: DeleteReceiptSummaryFieldInput!
  $condition: ModelReceiptSummaryFieldConditionInput
) {
  deleteReceiptSummaryField(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReceiptSummaryFieldMutationVariables,
  APITypes.DeleteReceiptSummaryFieldMutation
>;
export const createReceiptLineItem = /* GraphQL */ `mutation CreateReceiptLineItem(
  $input: CreateReceiptLineItemInput!
  $condition: ModelReceiptLineItemConditionInput
) {
  createReceiptLineItem(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    lineItemFields {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReceiptLineItemMutationVariables,
  APITypes.CreateReceiptLineItemMutation
>;
export const updateReceiptLineItem = /* GraphQL */ `mutation UpdateReceiptLineItem(
  $input: UpdateReceiptLineItemInput!
  $condition: ModelReceiptLineItemConditionInput
) {
  updateReceiptLineItem(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    lineItemFields {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReceiptLineItemMutationVariables,
  APITypes.UpdateReceiptLineItemMutation
>;
export const deleteReceiptLineItem = /* GraphQL */ `mutation DeleteReceiptLineItem(
  $input: DeleteReceiptLineItemInput!
  $condition: ModelReceiptLineItemConditionInput
) {
  deleteReceiptLineItem(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    lineItemFields {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReceiptLineItemMutationVariables,
  APITypes.DeleteReceiptLineItemMutation
>;
export const createReceiptLineItemField = /* GraphQL */ `mutation CreateReceiptLineItemField(
  $input: CreateReceiptLineItemFieldInput!
  $condition: ModelReceiptLineItemFieldConditionInput
) {
  createReceiptLineItemField(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    receiptLineItemId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReceiptLineItemFieldMutationVariables,
  APITypes.CreateReceiptLineItemFieldMutation
>;
export const updateReceiptLineItemField = /* GraphQL */ `mutation UpdateReceiptLineItemField(
  $input: UpdateReceiptLineItemFieldInput!
  $condition: ModelReceiptLineItemFieldConditionInput
) {
  updateReceiptLineItemField(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    receiptLineItemId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReceiptLineItemFieldMutationVariables,
  APITypes.UpdateReceiptLineItemFieldMutation
>;
export const deleteReceiptLineItemField = /* GraphQL */ `mutation DeleteReceiptLineItemField(
  $input: DeleteReceiptLineItemFieldInput!
  $condition: ModelReceiptLineItemFieldConditionInput
) {
  deleteReceiptLineItemField(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    receiptLineItemId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReceiptLineItemFieldMutationVariables,
  APITypes.DeleteReceiptLineItemFieldMutation
>;
export const createReceiptUpload = /* GraphQL */ `mutation CreateReceiptUpload(
  $input: CreateReceiptUploadInput!
  $condition: ModelReceiptUploadConditionInput
) {
  createReceiptUpload(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    document
    status
    summaryFields {
      nextToken
      __typename
    }
    lineItems {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReceiptUploadMutationVariables,
  APITypes.CreateReceiptUploadMutation
>;
export const updateReceiptUpload = /* GraphQL */ `mutation UpdateReceiptUpload(
  $input: UpdateReceiptUploadInput!
  $condition: ModelReceiptUploadConditionInput
) {
  updateReceiptUpload(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    document
    status
    summaryFields {
      nextToken
      __typename
    }
    lineItems {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReceiptUploadMutationVariables,
  APITypes.UpdateReceiptUploadMutation
>;
export const deleteReceiptUpload = /* GraphQL */ `mutation DeleteReceiptUpload(
  $input: DeleteReceiptUploadInput!
  $condition: ModelReceiptUploadConditionInput
) {
  deleteReceiptUpload(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    document
    status
    summaryFields {
      nextToken
      __typename
    }
    lineItems {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReceiptUploadMutationVariables,
  APITypes.DeleteReceiptUploadMutation
>;
export const createTransaction = /* GraphQL */ `mutation CreateTransaction(
  $input: CreateTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  createTransaction(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    transactionDate
    reimbursementTransactionId
    reimbursementTransaction {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
    highnoteTransactionId
    highnoteTransaction {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      flexCodeId
      flexCostCodeId
      flexCostTypeId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTransactionMutationVariables,
  APITypes.CreateTransactionMutation
>;
export const updateTransaction = /* GraphQL */ `mutation UpdateTransaction(
  $input: UpdateTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  updateTransaction(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    transactionDate
    reimbursementTransactionId
    reimbursementTransaction {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
    highnoteTransactionId
    highnoteTransaction {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      flexCodeId
      flexCostCodeId
      flexCostTypeId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTransactionMutationVariables,
  APITypes.UpdateTransactionMutation
>;
export const deleteTransaction = /* GraphQL */ `mutation DeleteTransaction(
  $input: DeleteTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  deleteTransaction(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    transactionDate
    reimbursementTransactionId
    reimbursementTransaction {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
    highnoteTransactionId
    highnoteTransaction {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      flexCodeId
      flexCostCodeId
      flexCostTypeId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTransactionMutationVariables,
  APITypes.DeleteTransactionMutation
>;
export const createReimbursementTransaction = /* GraphQL */ `mutation CreateReimbursementTransaction(
  $input: CreateReimbursementTransactionInput!
  $condition: ModelReimbursementTransactionConditionInput
) {
  createReimbursementTransaction(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    dwollaTransferId
    transactionDate
    amount {
      value
      currencyCode
      __typename
    }
    status
    checkrunStatus
    note
    contestNote
    deniedNote
    merchantName
    receiptLineItems
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    reimbursementDate
    paidolUserId
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    transactionType
    expenseTypeId
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReimbursementTransactionMutationVariables,
  APITypes.CreateReimbursementTransactionMutation
>;
export const updateReimbursementTransaction = /* GraphQL */ `mutation UpdateReimbursementTransaction(
  $input: UpdateReimbursementTransactionInput!
  $condition: ModelReimbursementTransactionConditionInput
) {
  updateReimbursementTransaction(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    dwollaTransferId
    transactionDate
    amount {
      value
      currencyCode
      __typename
    }
    status
    checkrunStatus
    note
    contestNote
    deniedNote
    merchantName
    receiptLineItems
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    reimbursementDate
    paidolUserId
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    transactionType
    expenseTypeId
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReimbursementTransactionMutationVariables,
  APITypes.UpdateReimbursementTransactionMutation
>;
export const deleteReimbursementTransaction = /* GraphQL */ `mutation DeleteReimbursementTransaction(
  $input: DeleteReimbursementTransactionInput!
  $condition: ModelReimbursementTransactionConditionInput
) {
  deleteReimbursementTransaction(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    dwollaTransferId
    transactionDate
    amount {
      value
      currencyCode
      __typename
    }
    status
    checkrunStatus
    note
    contestNote
    deniedNote
    merchantName
    receiptLineItems
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    reimbursementDate
    paidolUserId
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    transactionType
    expenseTypeId
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReimbursementTransactionMutationVariables,
  APITypes.DeleteReimbursementTransactionMutation
>;
export const createHighnoteTransaction = /* GraphQL */ `mutation CreateHighnoteTransaction(
  $input: CreateHighnoteTransactionInput!
  $condition: ModelHighnoteTransactionConditionInput
) {
  createHighnoteTransaction(input: $input, condition: $condition) {
    transactionId
    paymentCardId
    paymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    paidolId
    authPaidolId
    cardGroupId
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    status
    transactionDate
    approvedAmount {
      value
      currencyCode
      __typename
    }
    merchantName
    merchantCategoryCode
    merchantCategory
    transactionEvents
    note
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    procoreCompanyId
    procoreCompany {
      id
      name
      is_active
      logo_url
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    procoreDirectCostId
    procoreDirectCostLineItemId
    agaveProjectId
    agaveProject {
      id
      source_id
      amount
      completion_date
      name
      number
      start_date
      status
      type
      source_create_time
      source_update_time
      __typename
    }
    agaveCostCodeId
    agaveCostCode {
      id
      source_id
      code
      full_code
      name
      parent_id
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveCostTypeId
    agaveCostType {
      id
      source_id
      code
      name
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveExpenseId
    agaveExpense {
      id
      source_id
      amount
      amount_due
      ap_payment_ids
      batch
      batch_id
      creator_id
      currency_code
      description
      discount_amount
      discount_date
      due_date
      issue_date
      misc_amount
      net_amount
      number
      project_id
      retention_amount
      retention_percent
      status
      subtotal_amount
      tax_amount
      total_amount
      type
      vendor_id
      source_create_time
      source_update_time
      __typename
    }
    agaveVendor {
      id
      code
      email
      fax
      name
      phone
      status
      tax_number
      terms
      type
      website
      __typename
    }
    agaveVendorId
    receiptPath
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    erpApprovalStatus
    last4
    userId
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    approvedAmountValue
    approvedAmountCurrencyCode
    flexCodeId
    flexCode {
      id
      paidolId
      authPaidolId
      projectName
      projectCode
      isActive
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    flexCostCodeId
    flexCostCode {
      id
      paidolId
      authPaidolId
      code
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    flexCostTypeId
    flexCostType {
      id
      paidolId
      authPaidolId
      name
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHighnoteTransactionMutationVariables,
  APITypes.CreateHighnoteTransactionMutation
>;
export const updateHighnoteTransaction = /* GraphQL */ `mutation UpdateHighnoteTransaction(
  $input: UpdateHighnoteTransactionInput!
  $condition: ModelHighnoteTransactionConditionInput
) {
  updateHighnoteTransaction(input: $input, condition: $condition) {
    transactionId
    paymentCardId
    paymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    paidolId
    authPaidolId
    cardGroupId
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    status
    transactionDate
    approvedAmount {
      value
      currencyCode
      __typename
    }
    merchantName
    merchantCategoryCode
    merchantCategory
    transactionEvents
    note
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    procoreCompanyId
    procoreCompany {
      id
      name
      is_active
      logo_url
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    procoreDirectCostId
    procoreDirectCostLineItemId
    agaveProjectId
    agaveProject {
      id
      source_id
      amount
      completion_date
      name
      number
      start_date
      status
      type
      source_create_time
      source_update_time
      __typename
    }
    agaveCostCodeId
    agaveCostCode {
      id
      source_id
      code
      full_code
      name
      parent_id
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveCostTypeId
    agaveCostType {
      id
      source_id
      code
      name
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveExpenseId
    agaveExpense {
      id
      source_id
      amount
      amount_due
      ap_payment_ids
      batch
      batch_id
      creator_id
      currency_code
      description
      discount_amount
      discount_date
      due_date
      issue_date
      misc_amount
      net_amount
      number
      project_id
      retention_amount
      retention_percent
      status
      subtotal_amount
      tax_amount
      total_amount
      type
      vendor_id
      source_create_time
      source_update_time
      __typename
    }
    agaveVendor {
      id
      code
      email
      fax
      name
      phone
      status
      tax_number
      terms
      type
      website
      __typename
    }
    agaveVendorId
    receiptPath
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    erpApprovalStatus
    last4
    userId
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    approvedAmountValue
    approvedAmountCurrencyCode
    flexCodeId
    flexCode {
      id
      paidolId
      authPaidolId
      projectName
      projectCode
      isActive
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    flexCostCodeId
    flexCostCode {
      id
      paidolId
      authPaidolId
      code
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    flexCostTypeId
    flexCostType {
      id
      paidolId
      authPaidolId
      name
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHighnoteTransactionMutationVariables,
  APITypes.UpdateHighnoteTransactionMutation
>;
export const deleteHighnoteTransaction = /* GraphQL */ `mutation DeleteHighnoteTransaction(
  $input: DeleteHighnoteTransactionInput!
  $condition: ModelHighnoteTransactionConditionInput
) {
  deleteHighnoteTransaction(input: $input, condition: $condition) {
    transactionId
    paymentCardId
    paymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    paidolId
    authPaidolId
    cardGroupId
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    status
    transactionDate
    approvedAmount {
      value
      currencyCode
      __typename
    }
    merchantName
    merchantCategoryCode
    merchantCategory
    transactionEvents
    note
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    procoreCompanyId
    procoreCompany {
      id
      name
      is_active
      logo_url
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    procoreDirectCostId
    procoreDirectCostLineItemId
    agaveProjectId
    agaveProject {
      id
      source_id
      amount
      completion_date
      name
      number
      start_date
      status
      type
      source_create_time
      source_update_time
      __typename
    }
    agaveCostCodeId
    agaveCostCode {
      id
      source_id
      code
      full_code
      name
      parent_id
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveCostTypeId
    agaveCostType {
      id
      source_id
      code
      name
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveExpenseId
    agaveExpense {
      id
      source_id
      amount
      amount_due
      ap_payment_ids
      batch
      batch_id
      creator_id
      currency_code
      description
      discount_amount
      discount_date
      due_date
      issue_date
      misc_amount
      net_amount
      number
      project_id
      retention_amount
      retention_percent
      status
      subtotal_amount
      tax_amount
      total_amount
      type
      vendor_id
      source_create_time
      source_update_time
      __typename
    }
    agaveVendor {
      id
      code
      email
      fax
      name
      phone
      status
      tax_number
      terms
      type
      website
      __typename
    }
    agaveVendorId
    receiptPath
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    erpApprovalStatus
    last4
    userId
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    approvedAmountValue
    approvedAmountCurrencyCode
    flexCodeId
    flexCode {
      id
      paidolId
      authPaidolId
      projectName
      projectCode
      isActive
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    flexCostCodeId
    flexCostCode {
      id
      paidolId
      authPaidolId
      code
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    flexCostTypeId
    flexCostType {
      id
      paidolId
      authPaidolId
      name
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHighnoteTransactionMutationVariables,
  APITypes.DeleteHighnoteTransactionMutation
>;
export const createCardGroup = /* GraphQL */ `mutation CreateCardGroup(
  $input: CreateCardGroupInput!
  $condition: ModelCardGroupConditionInput
) {
  createCardGroup(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    name
    paymentCards {
      nextToken
      __typename
    }
    monthlyBudget {
      value
      currency
      __typename
    }
    isMonthlyBudgetUnlimited
    spendGroupId
    spendGroup {
      id
      paidolId
      authPaidolId
      name
      paymentCards
      merchantCountrySpendRuleId
      merchantCategorySpendRuleId
      amountLimitSpendRuleId
      createdAt
      updatedAt
      __typename
    }
    monthlyBudgetSpend {
      nextToken
      __typename
    }
    transactions {
      nextToken
      __typename
    }
    groupAdmins {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCardGroupMutationVariables,
  APITypes.CreateCardGroupMutation
>;
export const updateCardGroup = /* GraphQL */ `mutation UpdateCardGroup(
  $input: UpdateCardGroupInput!
  $condition: ModelCardGroupConditionInput
) {
  updateCardGroup(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    name
    paymentCards {
      nextToken
      __typename
    }
    monthlyBudget {
      value
      currency
      __typename
    }
    isMonthlyBudgetUnlimited
    spendGroupId
    spendGroup {
      id
      paidolId
      authPaidolId
      name
      paymentCards
      merchantCountrySpendRuleId
      merchantCategorySpendRuleId
      amountLimitSpendRuleId
      createdAt
      updatedAt
      __typename
    }
    monthlyBudgetSpend {
      nextToken
      __typename
    }
    transactions {
      nextToken
      __typename
    }
    groupAdmins {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCardGroupMutationVariables,
  APITypes.UpdateCardGroupMutation
>;
export const deleteCardGroup = /* GraphQL */ `mutation DeleteCardGroup(
  $input: DeleteCardGroupInput!
  $condition: ModelCardGroupConditionInput
) {
  deleteCardGroup(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    name
    paymentCards {
      nextToken
      __typename
    }
    monthlyBudget {
      value
      currency
      __typename
    }
    isMonthlyBudgetUnlimited
    spendGroupId
    spendGroup {
      id
      paidolId
      authPaidolId
      name
      paymentCards
      merchantCountrySpendRuleId
      merchantCategorySpendRuleId
      amountLimitSpendRuleId
      createdAt
      updatedAt
      __typename
    }
    monthlyBudgetSpend {
      nextToken
      __typename
    }
    transactions {
      nextToken
      __typename
    }
    groupAdmins {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCardGroupMutationVariables,
  APITypes.DeleteCardGroupMutation
>;
export const createPaidolUserToCardGroup = /* GraphQL */ `mutation CreatePaidolUserToCardGroup(
  $input: CreatePaidolUserToCardGroupInput!
  $condition: ModelPaidolUserToCardGroupConditionInput
) {
  createPaidolUserToCardGroup(input: $input, condition: $condition) {
    id
    paidolUserId
    cardGroupId
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaidolUserToCardGroupMutationVariables,
  APITypes.CreatePaidolUserToCardGroupMutation
>;
export const updatePaidolUserToCardGroup = /* GraphQL */ `mutation UpdatePaidolUserToCardGroup(
  $input: UpdatePaidolUserToCardGroupInput!
  $condition: ModelPaidolUserToCardGroupConditionInput
) {
  updatePaidolUserToCardGroup(input: $input, condition: $condition) {
    id
    paidolUserId
    cardGroupId
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaidolUserToCardGroupMutationVariables,
  APITypes.UpdatePaidolUserToCardGroupMutation
>;
export const deletePaidolUserToCardGroup = /* GraphQL */ `mutation DeletePaidolUserToCardGroup(
  $input: DeletePaidolUserToCardGroupInput!
  $condition: ModelPaidolUserToCardGroupConditionInput
) {
  deletePaidolUserToCardGroup(input: $input, condition: $condition) {
    id
    paidolUserId
    cardGroupId
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaidolUserToCardGroupMutationVariables,
  APITypes.DeletePaidolUserToCardGroupMutation
>;
export const createMerchantSpendRule = /* GraphQL */ `mutation CreateMerchantSpendRule(
  $input: CreateMerchantSpendRuleInput!
  $condition: ModelMerchantSpendRuleConditionInput
) {
  createMerchantSpendRule(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    name
    paymentCards
    merchantCountrySpendRuleId
    merchantCategorySpendRuleId
    amountLimitSpendRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMerchantSpendRuleMutationVariables,
  APITypes.CreateMerchantSpendRuleMutation
>;
export const updateMerchantSpendRule = /* GraphQL */ `mutation UpdateMerchantSpendRule(
  $input: UpdateMerchantSpendRuleInput!
  $condition: ModelMerchantSpendRuleConditionInput
) {
  updateMerchantSpendRule(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    name
    paymentCards
    merchantCountrySpendRuleId
    merchantCategorySpendRuleId
    amountLimitSpendRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMerchantSpendRuleMutationVariables,
  APITypes.UpdateMerchantSpendRuleMutation
>;
export const deleteMerchantSpendRule = /* GraphQL */ `mutation DeleteMerchantSpendRule(
  $input: DeleteMerchantSpendRuleInput!
  $condition: ModelMerchantSpendRuleConditionInput
) {
  deleteMerchantSpendRule(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    name
    paymentCards
    merchantCountrySpendRuleId
    merchantCategorySpendRuleId
    amountLimitSpendRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMerchantSpendRuleMutationVariables,
  APITypes.DeleteMerchantSpendRuleMutation
>;
export const createTransactionsDownloadQueue = /* GraphQL */ `mutation CreateTransactionsDownloadQueue(
  $input: CreateTransactionsDownloadQueueInput!
  $condition: ModelTransactionsDownloadQueueConditionInput
) {
  createTransactionsDownloadQueue(input: $input, condition: $condition) {
    id
    user_id
    paidol_id
    range_option
    start_date
    end_date
    columns
    s3_key
    cron_status
    createdAt
    paidolUserId
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTransactionsDownloadQueueMutationVariables,
  APITypes.CreateTransactionsDownloadQueueMutation
>;
export const updateTransactionsDownloadQueue = /* GraphQL */ `mutation UpdateTransactionsDownloadQueue(
  $input: UpdateTransactionsDownloadQueueInput!
  $condition: ModelTransactionsDownloadQueueConditionInput
) {
  updateTransactionsDownloadQueue(input: $input, condition: $condition) {
    id
    user_id
    paidol_id
    range_option
    start_date
    end_date
    columns
    s3_key
    cron_status
    createdAt
    paidolUserId
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTransactionsDownloadQueueMutationVariables,
  APITypes.UpdateTransactionsDownloadQueueMutation
>;
export const deleteTransactionsDownloadQueue = /* GraphQL */ `mutation DeleteTransactionsDownloadQueue(
  $input: DeleteTransactionsDownloadQueueInput!
  $condition: ModelTransactionsDownloadQueueConditionInput
) {
  deleteTransactionsDownloadQueue(input: $input, condition: $condition) {
    id
    user_id
    paidol_id
    range_option
    start_date
    end_date
    columns
    s3_key
    cron_status
    createdAt
    paidolUserId
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTransactionsDownloadQueueMutationVariables,
  APITypes.DeleteTransactionsDownloadQueueMutation
>;
export const createTransactionsReceiptsDownloadQueue = /* GraphQL */ `mutation CreateTransactionsReceiptsDownloadQueue(
  $input: CreateTransactionsReceiptsDownloadQueueInput!
  $condition: ModelTransactionsReceiptsDownloadQueueConditionInput
) {
  createTransactionsReceiptsDownloadQueue(
    input: $input
    condition: $condition
  ) {
    id
    user_id
    paidol_id
    paidolUserId
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTransactionsReceiptsDownloadQueueMutationVariables,
  APITypes.CreateTransactionsReceiptsDownloadQueueMutation
>;
export const updateTransactionsReceiptsDownloadQueue = /* GraphQL */ `mutation UpdateTransactionsReceiptsDownloadQueue(
  $input: UpdateTransactionsReceiptsDownloadQueueInput!
  $condition: ModelTransactionsReceiptsDownloadQueueConditionInput
) {
  updateTransactionsReceiptsDownloadQueue(
    input: $input
    condition: $condition
  ) {
    id
    user_id
    paidol_id
    paidolUserId
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTransactionsReceiptsDownloadQueueMutationVariables,
  APITypes.UpdateTransactionsReceiptsDownloadQueueMutation
>;
export const deleteTransactionsReceiptsDownloadQueue = /* GraphQL */ `mutation DeleteTransactionsReceiptsDownloadQueue(
  $input: DeleteTransactionsReceiptsDownloadQueueInput!
  $condition: ModelTransactionsReceiptsDownloadQueueConditionInput
) {
  deleteTransactionsReceiptsDownloadQueue(
    input: $input
    condition: $condition
  ) {
    id
    user_id
    paidol_id
    paidolUserId
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTransactionsReceiptsDownloadQueueMutationVariables,
  APITypes.DeleteTransactionsReceiptsDownloadQueueMutation
>;
export const createSpendRuleConfig = /* GraphQL */ `mutation CreateSpendRuleConfig(
  $input: CreateSpendRuleConfigInput!
  $condition: ModelSpendRuleConfigConditionInput
) {
  createSpendRuleConfig(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    ruleId
    ruleType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSpendRuleConfigMutationVariables,
  APITypes.CreateSpendRuleConfigMutation
>;
export const updateSpendRuleConfig = /* GraphQL */ `mutation UpdateSpendRuleConfig(
  $input: UpdateSpendRuleConfigInput!
  $condition: ModelSpendRuleConfigConditionInput
) {
  updateSpendRuleConfig(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    ruleId
    ruleType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSpendRuleConfigMutationVariables,
  APITypes.UpdateSpendRuleConfigMutation
>;
export const deleteSpendRuleConfig = /* GraphQL */ `mutation DeleteSpendRuleConfig(
  $input: DeleteSpendRuleConfigInput!
  $condition: ModelSpendRuleConfigConditionInput
) {
  deleteSpendRuleConfig(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    ruleId
    ruleType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSpendRuleConfigMutationVariables,
  APITypes.DeleteSpendRuleConfigMutation
>;
export const createJobCodeImport = /* GraphQL */ `mutation CreateJobCodeImport(
  $input: CreateJobCodeImportInput!
  $condition: ModelJobCodeImportConditionInput
) {
  createJobCodeImport(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    jobCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJobCodeImportMutationVariables,
  APITypes.CreateJobCodeImportMutation
>;
export const updateJobCodeImport = /* GraphQL */ `mutation UpdateJobCodeImport(
  $input: UpdateJobCodeImportInput!
  $condition: ModelJobCodeImportConditionInput
) {
  updateJobCodeImport(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    jobCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobCodeImportMutationVariables,
  APITypes.UpdateJobCodeImportMutation
>;
export const deleteJobCodeImport = /* GraphQL */ `mutation DeleteJobCodeImport(
  $input: DeleteJobCodeImportInput!
  $condition: ModelJobCodeImportConditionInput
) {
  deleteJobCodeImport(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    jobCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJobCodeImportMutationVariables,
  APITypes.DeleteJobCodeImportMutation
>;
export const createJobCode = /* GraphQL */ `mutation CreateJobCode(
  $input: CreateJobCodeInput!
  $condition: ModelJobCodeConditionInput
) {
  createJobCode(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    description
    code
    isActive
    budget
    jobCodeImportId
    jobCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJobCodeMutationVariables,
  APITypes.CreateJobCodeMutation
>;
export const updateJobCode = /* GraphQL */ `mutation UpdateJobCode(
  $input: UpdateJobCodeInput!
  $condition: ModelJobCodeConditionInput
) {
  updateJobCode(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    description
    code
    isActive
    budget
    jobCodeImportId
    jobCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobCodeMutationVariables,
  APITypes.UpdateJobCodeMutation
>;
export const deleteJobCode = /* GraphQL */ `mutation DeleteJobCode(
  $input: DeleteJobCodeInput!
  $condition: ModelJobCodeConditionInput
) {
  deleteJobCode(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    description
    code
    isActive
    budget
    jobCodeImportId
    jobCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJobCodeMutationVariables,
  APITypes.DeleteJobCodeMutation
>;
export const createFlexCodeImport = /* GraphQL */ `mutation CreateFlexCodeImport(
  $input: CreateFlexCodeImportInput!
  $condition: ModelFlexCodeImportConditionInput
) {
  createFlexCodeImport(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    flexCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlexCodeImportMutationVariables,
  APITypes.CreateFlexCodeImportMutation
>;
export const updateFlexCodeImport = /* GraphQL */ `mutation UpdateFlexCodeImport(
  $input: UpdateFlexCodeImportInput!
  $condition: ModelFlexCodeImportConditionInput
) {
  updateFlexCodeImport(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    flexCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlexCodeImportMutationVariables,
  APITypes.UpdateFlexCodeImportMutation
>;
export const deleteFlexCodeImport = /* GraphQL */ `mutation DeleteFlexCodeImport(
  $input: DeleteFlexCodeImportInput!
  $condition: ModelFlexCodeImportConditionInput
) {
  deleteFlexCodeImport(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    flexCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlexCodeImportMutationVariables,
  APITypes.DeleteFlexCodeImportMutation
>;
export const createFlexCode = /* GraphQL */ `mutation CreateFlexCode(
  $input: CreateFlexCodeInput!
  $condition: ModelFlexCodeConditionInput
) {
  createFlexCode(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    projectName
    projectCode
    isActive
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlexCodeMutationVariables,
  APITypes.CreateFlexCodeMutation
>;
export const updateFlexCode = /* GraphQL */ `mutation UpdateFlexCode(
  $input: UpdateFlexCodeInput!
  $condition: ModelFlexCodeConditionInput
) {
  updateFlexCode(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    projectName
    projectCode
    isActive
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlexCodeMutationVariables,
  APITypes.UpdateFlexCodeMutation
>;
export const deleteFlexCode = /* GraphQL */ `mutation DeleteFlexCode(
  $input: DeleteFlexCodeInput!
  $condition: ModelFlexCodeConditionInput
) {
  deleteFlexCode(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    projectName
    projectCode
    isActive
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlexCodeMutationVariables,
  APITypes.DeleteFlexCodeMutation
>;
export const createFlexCostCode = /* GraphQL */ `mutation CreateFlexCostCode(
  $input: CreateFlexCostCodeInput!
  $condition: ModelFlexCostCodeConditionInput
) {
  createFlexCostCode(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    code
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlexCostCodeMutationVariables,
  APITypes.CreateFlexCostCodeMutation
>;
export const updateFlexCostCode = /* GraphQL */ `mutation UpdateFlexCostCode(
  $input: UpdateFlexCostCodeInput!
  $condition: ModelFlexCostCodeConditionInput
) {
  updateFlexCostCode(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    code
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlexCostCodeMutationVariables,
  APITypes.UpdateFlexCostCodeMutation
>;
export const deleteFlexCostCode = /* GraphQL */ `mutation DeleteFlexCostCode(
  $input: DeleteFlexCostCodeInput!
  $condition: ModelFlexCostCodeConditionInput
) {
  deleteFlexCostCode(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    code
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlexCostCodeMutationVariables,
  APITypes.DeleteFlexCostCodeMutation
>;
export const createFlexCostType = /* GraphQL */ `mutation CreateFlexCostType(
  $input: CreateFlexCostTypeInput!
  $condition: ModelFlexCostTypeConditionInput
) {
  createFlexCostType(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    name
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlexCostTypeMutationVariables,
  APITypes.CreateFlexCostTypeMutation
>;
export const updateFlexCostType = /* GraphQL */ `mutation UpdateFlexCostType(
  $input: UpdateFlexCostTypeInput!
  $condition: ModelFlexCostTypeConditionInput
) {
  updateFlexCostType(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    name
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlexCostTypeMutationVariables,
  APITypes.UpdateFlexCostTypeMutation
>;
export const deleteFlexCostType = /* GraphQL */ `mutation DeleteFlexCostType(
  $input: DeleteFlexCostTypeInput!
  $condition: ModelFlexCostTypeConditionInput
) {
  deleteFlexCostType(input: $input, condition: $condition) {
    id
    paidolId
    authPaidolId
    name
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlexCostTypeMutationVariables,
  APITypes.DeleteFlexCostTypeMutation
>;
export const createPaidolUserToHighnotePaymentCard = /* GraphQL */ `mutation CreatePaidolUserToHighnotePaymentCard(
  $input: CreatePaidolUserToHighnotePaymentCardInput!
  $condition: ModelPaidolUserToHighnotePaymentCardConditionInput
) {
  createPaidolUserToHighnotePaymentCard(input: $input, condition: $condition) {
    id
    paidolUserID
    highnotePaymentCardID
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    highnotePaymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaidolUserToHighnotePaymentCardMutationVariables,
  APITypes.CreatePaidolUserToHighnotePaymentCardMutation
>;
export const updatePaidolUserToHighnotePaymentCard = /* GraphQL */ `mutation UpdatePaidolUserToHighnotePaymentCard(
  $input: UpdatePaidolUserToHighnotePaymentCardInput!
  $condition: ModelPaidolUserToHighnotePaymentCardConditionInput
) {
  updatePaidolUserToHighnotePaymentCard(input: $input, condition: $condition) {
    id
    paidolUserID
    highnotePaymentCardID
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    highnotePaymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaidolUserToHighnotePaymentCardMutationVariables,
  APITypes.UpdatePaidolUserToHighnotePaymentCardMutation
>;
export const deletePaidolUserToHighnotePaymentCard = /* GraphQL */ `mutation DeletePaidolUserToHighnotePaymentCard(
  $input: DeletePaidolUserToHighnotePaymentCardInput!
  $condition: ModelPaidolUserToHighnotePaymentCardConditionInput
) {
  deletePaidolUserToHighnotePaymentCard(input: $input, condition: $condition) {
    id
    paidolUserID
    highnotePaymentCardID
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    highnotePaymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaidolUserToHighnotePaymentCardMutationVariables,
  APITypes.DeletePaidolUserToHighnotePaymentCardMutation
>;
export const createExpenseTypeToMCC = /* GraphQL */ `mutation CreateExpenseTypeToMCC(
  $input: CreateExpenseTypeToMCCInput!
  $condition: ModelExpenseTypeToMCCConditionInput
) {
  createExpenseTypeToMCC(input: $input, condition: $condition) {
    id
    mccID
    expenseTypeID
    mcc {
      id
      code
      highnoteDescription
      highnoteCategoryDescription
      speedchainDescription
      createdAt
      updatedAt
      __typename
    }
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExpenseTypeToMCCMutationVariables,
  APITypes.CreateExpenseTypeToMCCMutation
>;
export const updateExpenseTypeToMCC = /* GraphQL */ `mutation UpdateExpenseTypeToMCC(
  $input: UpdateExpenseTypeToMCCInput!
  $condition: ModelExpenseTypeToMCCConditionInput
) {
  updateExpenseTypeToMCC(input: $input, condition: $condition) {
    id
    mccID
    expenseTypeID
    mcc {
      id
      code
      highnoteDescription
      highnoteCategoryDescription
      speedchainDescription
      createdAt
      updatedAt
      __typename
    }
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExpenseTypeToMCCMutationVariables,
  APITypes.UpdateExpenseTypeToMCCMutation
>;
export const deleteExpenseTypeToMCC = /* GraphQL */ `mutation DeleteExpenseTypeToMCC(
  $input: DeleteExpenseTypeToMCCInput!
  $condition: ModelExpenseTypeToMCCConditionInput
) {
  deleteExpenseTypeToMCC(input: $input, condition: $condition) {
    id
    mccID
    expenseTypeID
    mcc {
      id
      code
      highnoteDescription
      highnoteCategoryDescription
      speedchainDescription
      createdAt
      updatedAt
      __typename
    }
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExpenseTypeToMCCMutationVariables,
  APITypes.DeleteExpenseTypeToMCCMutation
>;
export const inviteUser = /* GraphQL */ `mutation InviteUser(
  $input: InviteUserToPaidol!
  $hostname: String!
  $highnotePaymentCardId: String
) {
  inviteUser(
    input: $input
    hostname: $hostname
    highnotePaymentCardId: $highnotePaymentCardId
  ) {
    id
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    position
    phone
    address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    roles
    is_test_fixture
    paymentCards {
      nextToken
      __typename
    }
    authorizedUserId
    authorizedUserApplicationId
    allowedJobCodes
    employee_id
    managedGroups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.InviteUserMutationVariables,
  APITypes.InviteUserMutation
>;
export const removeUserFromPaymentCard = /* GraphQL */ `mutation RemoveUserFromPaymentCard($paidolUserToHighnotePaymentCardId: ID!) {
  removeUserFromPaymentCard(
    paidolUserToHighnotePaymentCardId: $paidolUserToHighnotePaymentCardId
  ) {
    id
    paidolUserID
    highnotePaymentCardID
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    highnotePaymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveUserFromPaymentCardMutationVariables,
  APITypes.RemoveUserFromPaymentCardMutation
>;
export const updateSupplier = /* GraphQL */ `mutation UpdateSupplier($input: SupplierUpdateInput!) {
  updateSupplier(input: $input)
}
` as GeneratedMutation<
  APITypes.UpdateSupplierMutationVariables,
  APITypes.UpdateSupplierMutation
>;
export const hnGenerateTokenizeAccountHolderClientToken = /* GraphQL */ `mutation HnGenerateTokenizeAccountHolderClientToken {
  hnGenerateTokenizeAccountHolderClientToken {
    ... on HNClientToken {
      value
      expirationDate
      usage
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnGenerateTokenizeAccountHolderClientTokenMutationVariables,
  APITypes.HnGenerateTokenizeAccountHolderClientTokenMutation
>;
export const editBudget = /* GraphQL */ `mutation EditBudget($input: EditBudgetInput!) {
  editBudget(input: $input) {
    paidolId
    authPaidolId
    budget {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EditBudgetMutationVariables,
  APITypes.EditBudgetMutation
>;
export const hnGeneratePaymentCardClientToken = /* GraphQL */ `mutation HnGeneratePaymentCardClientToken(
  $input: HNGeneratePaymentCardClientTokenInput!
) {
  hnGeneratePaymentCardClientToken(input: $input) {
    ... on HNClientToken {
      value
      expirationDate
      usage
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnGeneratePaymentCardClientTokenMutationVariables,
  APITypes.HnGeneratePaymentCardClientTokenMutation
>;
export const hnAddExternalBankAccountVerifiedThroughPlaid = /* GraphQL */ `mutation HnAddExternalBankAccountVerifiedThroughPlaid(
  $input: HNAddExternalBankAccountVerifiedThroughPlaidInput!
) {
  hnAddExternalBankAccountVerifiedThroughPlaid(input: $input) {
    ... on HNExternalFinancialBankAccount {
      id
      ledgers {
        id
        name
        description
        normalBalance
        asOf
        __typename
      }
      owner {
        ... on HNOrganization {
          id
        }
        ... on HNPaymentCard {
          id
          bin
          last4
          expirationDate
          expirationMonth
          expirationYear
          network
          status
          formFactor
        }
        ... on HNUSPersonAccountHolder {
          id
          email
          dateOfBirth
          externalId
          createdAt
          updatedAt
        }
        ... on HNUSBusinessAccountHolder {
          id
          externalId
          createdAt
          updatedAt
        }
      }
      name
      provider
      accountStatus
      features {
        enabled
        createdAt
        updatedAt

        ... on HNOnDemandFundingFinancialAccountFeature {
          sourceFinancialAccountId
          pseudoBalanceEnabled
        }
      }
      createdAt
      updatedAt
      externalBankAccountDetails {
        id
        last4
        type
        routingNumber
        createdAt
        updatedAt
        __typename
      }
      integratorInitiatedAchTransfers {
        __typename
      }
      externallyInitiatedAchTransfers {
        __typename
      }
      integratorInitiatedACHTransfer {
        __typename
      }
      integratorACHTransfers {
        __typename
      }
      externallyInitiatedACHTransfer {
        __typename
      }
      externalACHTransfers {
        __typename
      }
      transactionEvents {
        __typename
      }
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnAddExternalBankAccountVerifiedThroughPlaidMutationVariables,
  APITypes.HnAddExternalBankAccountVerifiedThroughPlaidMutation
>;
export const hnAddExternalBankAccountVerifiedThroughFinicity = /* GraphQL */ `mutation HnAddExternalBankAccountVerifiedThroughFinicity(
  $input: HNAddExternalBankAccountVerifiedThroughFinicityInput!
) {
  hnAddExternalBankAccountVerifiedThroughFinicity(input: $input) {
    ... on HNExternalFinancialBankAccount {
      id
      ledgers {
        id
        name
        description
        normalBalance
        asOf
        __typename
      }
      owner {
        ... on HNOrganization {
          id
        }
        ... on HNPaymentCard {
          id
          bin
          last4
          expirationDate
          expirationMonth
          expirationYear
          network
          status
          formFactor
        }
        ... on HNUSPersonAccountHolder {
          id
          email
          dateOfBirth
          externalId
          createdAt
          updatedAt
        }
        ... on HNUSBusinessAccountHolder {
          id
          externalId
          createdAt
          updatedAt
        }
      }
      name
      provider
      accountStatus
      features {
        enabled
        createdAt
        updatedAt

        ... on HNOnDemandFundingFinancialAccountFeature {
          sourceFinancialAccountId
          pseudoBalanceEnabled
        }
      }
      createdAt
      updatedAt
      externalBankAccountDetails {
        id
        last4
        type
        routingNumber
        createdAt
        updatedAt
        __typename
      }
      integratorInitiatedAchTransfers {
        __typename
      }
      externallyInitiatedAchTransfers {
        __typename
      }
      integratorInitiatedACHTransfer {
        __typename
      }
      integratorACHTransfers {
        __typename
      }
      externallyInitiatedACHTransfer {
        __typename
      }
      externalACHTransfers {
        __typename
      }
      transactionEvents {
        __typename
      }
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnAddExternalBankAccountVerifiedThroughFinicityMutationVariables,
  APITypes.HnAddExternalBankAccountVerifiedThroughFinicityMutation
>;
export const hnCreateOneTimeACHTransfer = /* GraphQL */ `mutation HnCreateOneTimeACHTransfer($input: HNCreateOneTimeACHTransferInput!) {
  hnCreateOneTimeACHTransfer(input: $input) {
    ... on HNOneTimeACHTransfer {
      id
      fromFinancialAccount {
        ... on HNFinancialAccount {
          id
          externalId
          name
          createdAt
          updatedAt
          accountClosureBlockers
          accountStatus
          accountAttributes
        }
        ... on HNExternalFinancialBankAccount {
          id
          name
          provider
          accountStatus
          createdAt
          updatedAt
        }
        ... on HNNonVerifiedExternalUSFinancialBankAccount {
          id
          name
          accountStatus
          createdAt
          updatedAt
        }
      }
      toFinancialAccount {
        ... on HNFinancialAccount {
          id
          externalId
          name
          createdAt
          updatedAt
          accountClosureBlockers
          accountStatus
          accountAttributes
        }
        ... on HNExternalFinancialBankAccount {
          id
          name
          provider
          accountStatus
          createdAt
          updatedAt
        }
        ... on HNNonVerifiedExternalUSFinancialBankAccount {
          id
          name
          accountStatus
          createdAt
          updatedAt
        }
      }
      descriptor {
        companyEntryDescription
        individualName
        individualIdentificationNumber
        __typename
      }
      transferAmount {
        ... on HNTransferAmountCalculation {
          calculatedType
        }
      }
      status
      scheduledTransferDate
      transferEvents {
        __typename
      }
      createdAt
      updatedAt
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnCreateOneTimeACHTransferMutationVariables,
  APITypes.HnCreateOneTimeACHTransferMutation
>;
export const hnCancelScheduledTransfer = /* GraphQL */ `mutation HnCancelScheduledTransfer($input: HNCancelScheduledTransferInput!) {
  hnCancelScheduledTransfer(input: $input) {
    ... on HNRecurringACHTransfer {
      id
      fromFinancialAccount {
        ... on HNFinancialAccount {
          id
          externalId
          name
          createdAt
          updatedAt
          accountClosureBlockers
          accountStatus
          accountAttributes
        }
        ... on HNExternalFinancialBankAccount {
          id
          name
          provider
          accountStatus
          createdAt
          updatedAt
        }
        ... on HNNonVerifiedExternalUSFinancialBankAccount {
          id
          name
          accountStatus
          createdAt
          updatedAt
        }
      }
      toFinancialAccount {
        ... on HNFinancialAccount {
          id
          externalId
          name
          createdAt
          updatedAt
          accountClosureBlockers
          accountStatus
          accountAttributes
        }
        ... on HNExternalFinancialBankAccount {
          id
          name
          provider
          accountStatus
          createdAt
          updatedAt
        }
        ... on HNNonVerifiedExternalUSFinancialBankAccount {
          id
          name
          accountStatus
          createdAt
          updatedAt
        }
      }
      descriptor {
        companyEntryDescription
        individualName
        individualIdentificationNumber
        __typename
      }
      transferAmount {
        ... on HNTransferAmountCalculation {
          calculatedType
        }
      }
      frequency
      balanceAmountType
      status
      nextScheduledTransferDate
      transferEvents {
        __typename
      }
      createdAt
      updatedAt
    }
    ... on HNOneTimeACHTransfer {
      id
      fromFinancialAccount {
        ... on HNFinancialAccount {
          id
          externalId
          name
          createdAt
          updatedAt
          accountClosureBlockers
          accountStatus
          accountAttributes
        }
        ... on HNExternalFinancialBankAccount {
          id
          name
          provider
          accountStatus
          createdAt
          updatedAt
        }
        ... on HNNonVerifiedExternalUSFinancialBankAccount {
          id
          name
          accountStatus
          createdAt
          updatedAt
        }
      }
      toFinancialAccount {
        ... on HNFinancialAccount {
          id
          externalId
          name
          createdAt
          updatedAt
          accountClosureBlockers
          accountStatus
          accountAttributes
        }
        ... on HNExternalFinancialBankAccount {
          id
          name
          provider
          accountStatus
          createdAt
          updatedAt
        }
        ... on HNNonVerifiedExternalUSFinancialBankAccount {
          id
          name
          accountStatus
          createdAt
          updatedAt
        }
      }
      descriptor {
        companyEntryDescription
        individualName
        individualIdentificationNumber
        __typename
      }
      transferAmount {
        ... on HNTransferAmountCalculation {
          calculatedType
        }
      }
      status
      scheduledTransferDate
      transferEvents {
        __typename
      }
      createdAt
      updatedAt
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnCancelScheduledTransferMutationVariables,
  APITypes.HnCancelScheduledTransferMutation
>;
export const hnCreateRecurringACHTransfer = /* GraphQL */ `mutation HnCreateRecurringACHTransfer(
  $input: HNCreateRecurringACHTransferInput!
) {
  hnCreateRecurringACHTransfer(input: $input) {
    ... on HNRecurringACHTransfer {
      id
      fromFinancialAccount {
        ... on HNFinancialAccount {
          id
          externalId
          name
          createdAt
          updatedAt
          accountClosureBlockers
          accountStatus
          accountAttributes
        }
        ... on HNExternalFinancialBankAccount {
          id
          name
          provider
          accountStatus
          createdAt
          updatedAt
        }
        ... on HNNonVerifiedExternalUSFinancialBankAccount {
          id
          name
          accountStatus
          createdAt
          updatedAt
        }
      }
      toFinancialAccount {
        ... on HNFinancialAccount {
          id
          externalId
          name
          createdAt
          updatedAt
          accountClosureBlockers
          accountStatus
          accountAttributes
        }
        ... on HNExternalFinancialBankAccount {
          id
          name
          provider
          accountStatus
          createdAt
          updatedAt
        }
        ... on HNNonVerifiedExternalUSFinancialBankAccount {
          id
          name
          accountStatus
          createdAt
          updatedAt
        }
      }
      descriptor {
        companyEntryDescription
        individualName
        individualIdentificationNumber
        __typename
      }
      transferAmount {
        ... on HNTransferAmountCalculation {
          calculatedType
        }
      }
      frequency
      balanceAmountType
      status
      nextScheduledTransferDate
      transferEvents {
        __typename
      }
      createdAt
      updatedAt
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnCreateRecurringACHTransferMutationVariables,
  APITypes.HnCreateRecurringACHTransferMutation
>;
export const hnInitiateFundsDepositACHTransfer = /* GraphQL */ `mutation HnInitiateFundsDepositACHTransfer(
  $input: HNInitiateFundsDepositACHTransferInput!
) {
  hnInitiateFundsDepositACHTransfer(input: $input) {
    ... on HNIntegratorInitiatedFundsDepositACHTransfer {
      id
      amount {
        value
        currencyCode
        __typename
      }
      createdAt
      updatedAt
      ledgers {
        id
        name
        description
        normalBalance
        asOf
        __typename
      }
      status {
        ... on HNNonFailureIntegratorInitiatedACHStatus {
          status
        }
        ... on HNFailureIntegratorInitiatedACHStatus {
          status
          statusReasonCode
        }
      }
      fromFinancialAccount {
        ... on HNFinancialAccount {
          id
          externalId
          name
          createdAt
          updatedAt
          accountClosureBlockers
          accountStatus
          accountAttributes
        }
        ... on HNExternalFinancialBankAccount {
          id
          name
          provider
          accountStatus
          createdAt
          updatedAt
        }
        ... on HNNonVerifiedExternalUSFinancialBankAccount {
          id
          name
          accountStatus
          createdAt
          updatedAt
        }
      }
      toFinancialAccount {
        ... on HNFinancialAccount {
          id
          externalId
          name
          createdAt
          updatedAt
          accountClosureBlockers
          accountStatus
          accountAttributes
        }
        ... on HNExternalFinancialBankAccount {
          id
          name
          provider
          accountStatus
          createdAt
          updatedAt
        }
        ... on HNNonVerifiedExternalUSFinancialBankAccount {
          id
          name
          accountStatus
          createdAt
          updatedAt
        }
      }
      companyName
      descriptor {
        companyEntryDescription
        individualName
        individualIdentificationNumber
        __typename
      }
      companyIdentifier
      settlementDate
      fundsAvailableDate
      sameDay
      cardProduct {
        id
        name
        usage
        vertical
        commercial
        sourceTemplateId
        __typename
      }
      paymentRelatedInformation
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnInitiateFundsDepositACHTransferMutationVariables,
  APITypes.HnInitiateFundsDepositACHTransferMutation
>;
export const hnActivatePaymentCard = /* GraphQL */ `mutation HnActivatePaymentCard($input: HNActivatePaymentCardInput!) {
  hnActivatePaymentCard(input: $input) {
    ... on HNPaymentCard {
      id
      bin
      last4
      expirationDate
      expirationMonth
      expirationYear
      network
      cardProduct {
        id
        name
        usage
        vertical
        commercial
        sourceTemplateId
        __typename
      }
      cardProductApplication {
        ... on HNAccountHolderCardProductApplication {
          id
          applicationDenialReason
          createdAt
          updatedAt
        }
        ... on HNAuthorizedUserCardProductApplication {
          id
          applicationDenialReason
          createdAt
          updatedAt
        }
      }
      application {
        id
        applicationDenialReason
        createdAt
        updatedAt
        __typename
      }
      status
      formFactor
      originalPaymentCard {
        id
        bin
        last4
        expirationDate
        expirationMonth
        expirationYear
        network
        status
        formFactor
        __typename
      }
      restrictedDetails {
        ... on HNPaymentCardRestrictedDetails {
          number
          cvv
        }
        ... on HNAccessDeniedError {
          message
        }
      }
      atmLocations {
        ... on HNAccessDeniedError {
          message
        }
      }
      transactionEvents {
        __typename
      }
      financialAccounts {
        id
        externalId
        name
        createdAt
        updatedAt
        accountClosureBlockers
        accountStatus
        accountAttributes
        __typename
      }
      physicalPaymentCardOrders {
        id
        createdAt
        updatedAt
        __typename
      }
      attachedSpendRules {
        __typename
      }
      cardDigitalWalletTokens {
        __typename
      }
      velocityRuleBalances {
        __typename
      }
      velocitySpendRuleBalances {
        ... on HNVelocitySpendRuleAmountBalance {
          attachedLevel
        }
        ... on HNVelocitySpendRuleCountBalance {
          currentCountBalance
          remainingCountBalance
          attachedLevel
        }
      }
      attachedVelocityRules {
        __typename
      }
      paymentCardHolderDetails {
        __typename
      }
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnActivatePaymentCardMutationVariables,
  APITypes.HnActivatePaymentCardMutation
>;
export const hnCreateAccountHolderCardProductApplication = /* GraphQL */ `mutation HnCreateAccountHolderCardProductApplication(
  $input: HNCreateAccountHolderCardProductApplicationInput!
) {
  hnCreateAccountHolderCardProductApplication(input: $input) {
    ... on HNAccountHolderCardProductApplication {
      id
      applicationState {
        status
        __typename
      }
      adverseActionReasons {
        code
        description
        __typename
      }
      applicationDenialReason
      accountHolderSnapshot {
        ... on HNUSPersonAccountHolderSnapshot {
          email
          dateOfBirth
        }
      }
      cardProduct {
        id
        name
        usage
        vertical
        commercial
        sourceTemplateId
        __typename
      }
      applicationHistory {
        __typename
      }
      createdAt
      updatedAt
      financialAccounts {
        __typename
      }
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnCreateAccountHolderCardProductApplicationMutationVariables,
  APITypes.HnCreateAccountHolderCardProductApplicationMutation
>;
export const hnStartDocumentUploadSession = /* GraphQL */ `mutation HnStartDocumentUploadSession(
  $input: HNStartDocumentUploadSessionInput!
) {
  hnStartDocumentUploadSession(input: $input) {
    ... on HNUSAccountHolderApplicationDocumentUploadSession {
      id
      status
      primaryDocumentTypes
      secondaryDocumentTypes
      supportingDocumentTypes
      uploadRequirements {
        __typename
      }
      documents {
        createdAt
        documentType
        id
        status
        updatedAt
        uploadUrl
        downloadUrl
        __typename
      }
      createdAt
      updatedAt
    }
    ... on HNCardProfileDocumentUploadSession {
      documents {
        createdAt
        documentType
        id
        status
        updatedAt
        uploadUrl
        downloadUrl
        __typename
      }
      id
      status
      updatedAt
      createdAt
      expiration
      cardProduct {
        id
        name
        usage
        vertical
        commercial
        sourceTemplateId
        __typename
      }
      allowedDocumentTypes
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnStartDocumentUploadSessionMutationVariables,
  APITypes.HnStartDocumentUploadSessionMutation
>;
export const hnCreateDocumentUploadLink = /* GraphQL */ `mutation HnCreateDocumentUploadLink($input: HNCreateDocumentUploadLinkInput!) {
  hnCreateDocumentUploadLink(input: $input) {
    ... on HNDocumentUploadLink {
      id
      documentUploadSession {
        id
        status
        createdAt
        updatedAt

        ... on HNUSAccountHolderApplicationDocumentUploadSession {
          primaryDocumentTypes
          secondaryDocumentTypes
          supportingDocumentTypes
        }
        ... on HNCardProfileDocumentUploadSession {
          expiration
          allowedDocumentTypes
        }
      }
      documentType
      status
      documentCategoryType
      uploadUrl
      createdAt
      updatedAt
    }
    ... on HNCardArtDocument {
      createdAt
      documentType
      documentUploadSession {
        id
        status
        updatedAt
        createdAt
        expiration
        allowedDocumentTypes
        __typename
      }
      id
      status
      updatedAt
      uploadUrl
      downloadUrl
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnCreateDocumentUploadLinkMutationVariables,
  APITypes.HnCreateDocumentUploadLinkMutation
>;
export const hnEndDocumentUploadSession = /* GraphQL */ `mutation HnEndDocumentUploadSession($input: HNEndDocumentUploadSessionInput!) {
  hnEndDocumentUploadSession(input: $input) {
    ... on HNUSAccountHolderApplicationDocumentUploadSession {
      id
      status
      primaryDocumentTypes
      secondaryDocumentTypes
      supportingDocumentTypes
      uploadRequirements {
        __typename
      }
      documents {
        createdAt
        documentType
        id
        status
        updatedAt
        uploadUrl
        downloadUrl
        __typename
      }
      createdAt
      updatedAt
    }
    ... on HNCardProfileDocumentUploadSession {
      documents {
        createdAt
        documentType
        id
        status
        updatedAt
        uploadUrl
        downloadUrl
        __typename
      }
      id
      status
      updatedAt
      createdAt
      expiration
      cardProduct {
        id
        name
        usage
        vertical
        commercial
        sourceTemplateId
        __typename
      }
      allowedDocumentTypes
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnEndDocumentUploadSessionMutationVariables,
  APITypes.HnEndDocumentUploadSessionMutation
>;
export const plaidLinkTokenCreate = /* GraphQL */ `mutation PlaidLinkTokenCreate($userId: ID!, $redirectUri: String!) {
  plaidLinkTokenCreate(userId: $userId, redirectUri: $redirectUri) {
    link_token
    expiration
    request_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PlaidLinkTokenCreateMutationVariables,
  APITypes.PlaidLinkTokenCreateMutation
>;
export const plaidLinkTokenExchange = /* GraphQL */ `mutation PlaidLinkTokenExchange($publicToken: String!, $accountId: String!) {
  plaidLinkTokenExchange(publicToken: $publicToken, accountId: $accountId) {
    processor_token
    request_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PlaidLinkTokenExchangeMutationVariables,
  APITypes.PlaidLinkTokenExchangeMutation
>;
export const hnCreateMerchantCategorySpendRule = /* GraphQL */ `mutation HnCreateMerchantCategorySpendRule(
  $input: HNCreateMerchantCategorySpendRuleInput!
) {
  hnCreateMerchantCategorySpendRule(input: $input) {
    ... on HNMerchantCategorySpendRule {
      id
      name
      allowed
      blocked
      version
      createdAt
      updatedAt
      revisions {
        __typename
      }
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnCreateMerchantCategorySpendRuleMutationVariables,
  APITypes.HnCreateMerchantCategorySpendRuleMutation
>;
export const hnCreateMerchantCountrySpendRule = /* GraphQL */ `mutation HnCreateMerchantCountrySpendRule(
  $input: HNCreateMerchantCountrySpendRuleInput!
) {
  hnCreateMerchantCountrySpendRule(input: $input) {
    ... on HNMerchantCountrySpendRule {
      id
      name
      allowed
      blocked
      version
      createdAt
      updatedAt
      revisions {
        __typename
      }
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnCreateMerchantCountrySpendRuleMutationVariables,
  APITypes.HnCreateMerchantCountrySpendRuleMutation
>;
export const hnUpdateMerchantCategorySpendRule = /* GraphQL */ `mutation HnUpdateMerchantCategorySpendRule(
  $input: HNUpdateMerchantCategorySpendRuleInput!
) {
  hnUpdateMerchantCategorySpendRule(input: $input) {
    ... on HNMerchantCategorySpendRule {
      id
      name
      allowed
      blocked
      version
      createdAt
      updatedAt
      revisions {
        __typename
      }
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnUpdateMerchantCategorySpendRuleMutationVariables,
  APITypes.HnUpdateMerchantCategorySpendRuleMutation
>;
export const hnUpdateMerchantCountrySpendRule = /* GraphQL */ `mutation HnUpdateMerchantCountrySpendRule(
  $input: HNUpdateMerchantCountrySpendRuleInput!
) {
  hnUpdateMerchantCountrySpendRule(input: $input) {
    ... on HNMerchantCountrySpendRule {
      id
      name
      allowed
      blocked
      version
      createdAt
      updatedAt
      revisions {
        __typename
      }
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnUpdateMerchantCountrySpendRuleMutationVariables,
  APITypes.HnUpdateMerchantCountrySpendRuleMutation
>;
export const hnCreateAmountLimitSpendRule = /* GraphQL */ `mutation HnCreateAmountLimitSpendRule(
  $input: HNCreateAmountLimitSpendRuleInput!
) {
  hnCreateAmountLimitSpendRule(input: $input) {
    ... on HNAmountLimitSpendRule {
      id
      name
      maximumAmount {
        value
        currencyCode
        __typename
      }
      version
      createdAt
      updatedAt
      revisions {
        __typename
      }
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnCreateAmountLimitSpendRuleMutationVariables,
  APITypes.HnCreateAmountLimitSpendRuleMutation
>;
export const hnSuspendPaymentCard = /* GraphQL */ `mutation HnSuspendPaymentCard($input: HNSuspendPaymentCardInput!) {
  hnSuspendPaymentCard(input: $input) {
    ... on HNPaymentCard {
      id
      bin
      last4
      expirationDate
      expirationMonth
      expirationYear
      network
      cardProduct {
        id
        name
        usage
        vertical
        commercial
        sourceTemplateId
        __typename
      }
      cardProductApplication {
        ... on HNAccountHolderCardProductApplication {
          id
          applicationDenialReason
          createdAt
          updatedAt
        }
        ... on HNAuthorizedUserCardProductApplication {
          id
          applicationDenialReason
          createdAt
          updatedAt
        }
      }
      application {
        id
        applicationDenialReason
        createdAt
        updatedAt
        __typename
      }
      status
      formFactor
      originalPaymentCard {
        id
        bin
        last4
        expirationDate
        expirationMonth
        expirationYear
        network
        status
        formFactor
        __typename
      }
      restrictedDetails {
        ... on HNPaymentCardRestrictedDetails {
          number
          cvv
        }
        ... on HNAccessDeniedError {
          message
        }
      }
      atmLocations {
        ... on HNAccessDeniedError {
          message
        }
      }
      transactionEvents {
        __typename
      }
      financialAccounts {
        id
        externalId
        name
        createdAt
        updatedAt
        accountClosureBlockers
        accountStatus
        accountAttributes
        __typename
      }
      physicalPaymentCardOrders {
        id
        createdAt
        updatedAt
        __typename
      }
      attachedSpendRules {
        __typename
      }
      cardDigitalWalletTokens {
        __typename
      }
      velocityRuleBalances {
        __typename
      }
      velocitySpendRuleBalances {
        ... on HNVelocitySpendRuleAmountBalance {
          attachedLevel
        }
        ... on HNVelocitySpendRuleCountBalance {
          currentCountBalance
          remainingCountBalance
          attachedLevel
        }
      }
      attachedVelocityRules {
        __typename
      }
      paymentCardHolderDetails {
        __typename
      }
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnSuspendPaymentCardMutationVariables,
  APITypes.HnSuspendPaymentCardMutation
>;
export const hnClosePaymentCard = /* GraphQL */ `mutation HnClosePaymentCard($input: HNClosePaymentCardInput!) {
  hnClosePaymentCard(input: $input) {
    ... on HNPaymentCard {
      id
      bin
      last4
      expirationDate
      expirationMonth
      expirationYear
      network
      cardProduct {
        id
        name
        usage
        vertical
        commercial
        sourceTemplateId
        __typename
      }
      cardProductApplication {
        ... on HNAccountHolderCardProductApplication {
          id
          applicationDenialReason
          createdAt
          updatedAt
        }
        ... on HNAuthorizedUserCardProductApplication {
          id
          applicationDenialReason
          createdAt
          updatedAt
        }
      }
      application {
        id
        applicationDenialReason
        createdAt
        updatedAt
        __typename
      }
      status
      formFactor
      originalPaymentCard {
        id
        bin
        last4
        expirationDate
        expirationMonth
        expirationYear
        network
        status
        formFactor
        __typename
      }
      restrictedDetails {
        ... on HNPaymentCardRestrictedDetails {
          number
          cvv
        }
        ... on HNAccessDeniedError {
          message
        }
      }
      atmLocations {
        ... on HNAccessDeniedError {
          message
        }
      }
      transactionEvents {
        __typename
      }
      financialAccounts {
        id
        externalId
        name
        createdAt
        updatedAt
        accountClosureBlockers
        accountStatus
        accountAttributes
        __typename
      }
      physicalPaymentCardOrders {
        id
        createdAt
        updatedAt
        __typename
      }
      attachedSpendRules {
        __typename
      }
      cardDigitalWalletTokens {
        __typename
      }
      velocityRuleBalances {
        __typename
      }
      velocitySpendRuleBalances {
        ... on HNVelocitySpendRuleAmountBalance {
          attachedLevel
        }
        ... on HNVelocitySpendRuleCountBalance {
          currentCountBalance
          remainingCountBalance
          attachedLevel
        }
      }
      attachedVelocityRules {
        __typename
      }
      paymentCardHolderDetails {
        __typename
      }
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnClosePaymentCardMutationVariables,
  APITypes.HnClosePaymentCardMutation
>;
export const hnGenerateDirectDepositDetailClientToken = /* GraphQL */ `mutation HnGenerateDirectDepositDetailClientToken(
  $input: HNGenerateDirectDepositDetailClientTokenInput!
) {
  hnGenerateDirectDepositDetailClientToken(input: $input) {
    ... on HNClientToken {
      value
      expirationDate
      usage
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnGenerateDirectDepositDetailClientTokenMutationVariables,
  APITypes.HnGenerateDirectDepositDetailClientTokenMutation
>;
export const setThresholdAmount = /* GraphQL */ `mutation SetThresholdAmount($input: SetThresholdAmount!) {
  setThresholdAmount(input: $input) {
    id
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    position
    phone
    address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    roles
    is_test_fixture
    paymentCards {
      nextToken
      __typename
    }
    authorizedUserId
    authorizedUserApplicationId
    allowedJobCodes
    employee_id
    managedGroups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetThresholdAmountMutationVariables,
  APITypes.SetThresholdAmountMutation
>;
export const issuePaymentCard = /* GraphQL */ `mutation IssuePaymentCard($input: IssuePaymentCard!) {
  issuePaymentCard(input: $input) {
    statusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.IssuePaymentCardMutationVariables,
  APITypes.IssuePaymentCardMutation
>;
export const hnCloseExternalFinancialBankAccount = /* GraphQL */ `mutation HnCloseExternalFinancialBankAccount(
  $input: HNCloseExternalFinancialBankAccountInput!
) {
  hnCloseExternalFinancialBankAccount(input: $input) {
    ... on HNExternalFinancialBankAccount {
      id
      ledgers {
        id
        name
        description
        normalBalance
        asOf
        __typename
      }
      owner {
        ... on HNOrganization {
          id
        }
        ... on HNPaymentCard {
          id
          bin
          last4
          expirationDate
          expirationMonth
          expirationYear
          network
          status
          formFactor
        }
        ... on HNUSPersonAccountHolder {
          id
          email
          dateOfBirth
          externalId
          createdAt
          updatedAt
        }
        ... on HNUSBusinessAccountHolder {
          id
          externalId
          createdAt
          updatedAt
        }
      }
      name
      provider
      accountStatus
      features {
        enabled
        createdAt
        updatedAt

        ... on HNOnDemandFundingFinancialAccountFeature {
          sourceFinancialAccountId
          pseudoBalanceEnabled
        }
      }
      createdAt
      updatedAt
      externalBankAccountDetails {
        id
        last4
        type
        routingNumber
        createdAt
        updatedAt
        __typename
      }
      integratorInitiatedAchTransfers {
        __typename
      }
      externallyInitiatedAchTransfers {
        __typename
      }
      integratorInitiatedACHTransfer {
        __typename
      }
      integratorACHTransfers {
        __typename
      }
      externallyInitiatedACHTransfer {
        __typename
      }
      externalACHTransfers {
        __typename
      }
      transactionEvents {
        __typename
      }
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnCloseExternalFinancialBankAccountMutationVariables,
  APITypes.HnCloseExternalFinancialBankAccountMutation
>;
export const removeUser = /* GraphQL */ `mutation RemoveUser($input: RemoveUserInput!) {
  removeUser(input: $input) {
    paidolUserId
    userId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveUserMutationVariables,
  APITypes.RemoveUserMutation
>;
export const registerOnboard = /* GraphQL */ `mutation RegisterOnboard($input: RegisterOnboardInput!) {
  registerOnboard(input: $input)
}
` as GeneratedMutation<
  APITypes.RegisterOnboardMutationVariables,
  APITypes.RegisterOnboardMutation
>;
export const hnIssueFinancialAccountForApplication = /* GraphQL */ `mutation HnIssueFinancialAccountForApplication(
  $input: HNIssueFinancialAccountForApplicationInput!
) {
  hnIssueFinancialAccountForApplication(input: $input) {
    ... on HNFinancialAccount {
      id
      externalId
      ledgers {
        id
        name
        description
        normalBalance
        asOf
        __typename
      }
      owner {
        ... on HNOrganization {
          id
        }
        ... on HNPaymentCard {
          id
          bin
          last4
          expirationDate
          expirationMonth
          expirationYear
          network
          status
          formFactor
        }
        ... on HNUSPersonAccountHolder {
          id
          email
          dateOfBirth
          externalId
          createdAt
          updatedAt
        }
        ... on HNUSBusinessAccountHolder {
          id
          externalId
          createdAt
          updatedAt
        }
      }
      name
      cardProduct {
        id
        name
        usage
        vertical
        commercial
        sourceTemplateId
        __typename
      }
      application {
        id
        applicationDenialReason
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      features {
        enabled
        createdAt
        updatedAt

        ... on HNOnDemandFundingFinancialAccountFeature {
          sourceFinancialAccountId
          pseudoBalanceEnabled
        }
      }
      directDepositDetails {
        id
        createdAt
        updatedAt
        __typename
      }
      activeBillingCycleConfiguration {
        id
        paymentDueDayOfMonth
        billingCycleStartDayOfMonth
        billingCycleEndDayOfMonth
        from
        through
        __typename
      }
      statementSnapshot {
        asOf
        updatedAt
      }
      statements {
        __typename
      }
      financialAccountActivities {
        __typename
      }
      integratorInitiatedAchTransfers {
        __typename
      }
      integratorInitiatedACHTransfer {
        __typename
      }
      integratorACHTransfers {
        __typename
      }
      externallyInitiatedAchTransfers {
        __typename
      }
      externallyInitiatedACHTransfer {
        __typename
      }
      externalACHTransfers {
        __typename
      }
      incomingScheduledTransfers {
        __typename
      }
      transactionEvents {
        __typename
      }
      interFinancialAccountTransfers {
        __typename
      }
      accountClosureBlockers
      accountStatus
      accountAttributes
      paymentCards {
        __typename
      }
      velocityRuleBalances {
        __typename
      }
      velocitySpendRuleBalances {
        ... on HNVelocitySpendRuleAmountBalance {
          attachedLevel
        }
        ... on HNVelocitySpendRuleCountBalance {
          currentCountBalance
          remainingCountBalance
          attachedLevel
        }
      }
      delinquency {
        delinquencyStartedOn
        totalDaysDelinquent
        numberOfCycles
      }
      delinquencyState {
        state
        updatedAt
        __typename
      }
      billingCycleHistory {
        __typename
      }
      feeTransferEvents {
        __typename
      }
      paymentCardTransactionDisputes {
        ... on HNAccessDeniedError {
          message
        }
      }
      attachedSpendRules {
        __typename
      }
      attachedVelocityRules {
        __typename
      }
    }
    ... on HNUserError {
      errors {
        path
        code
        description
        __typename
      }
    }
    ... on HNAccessDeniedError {
      message
    }
  }
}
` as GeneratedMutation<
  APITypes.HnIssueFinancialAccountForApplicationMutationVariables,
  APITypes.HnIssueFinancialAccountForApplicationMutation
>;
export const createDwollaFinicityConsentReceipt = /* GraphQL */ `mutation CreateDwollaFinicityConsentReceipt(
  $input: CreateFinicityConsentReceiptInput!
) {
  createDwollaFinicityConsentReceipt(input: $input) {
    receipt {
      profile
      version
      receiptId
      receiptVersion
      customerId
      partnerId
      __typename
    }
    proof {
      signature
      keyId
      timestamp
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDwollaFinicityConsentReceiptMutationVariables,
  APITypes.CreateDwollaFinicityConsentReceiptMutation
>;
export const createHighnoteFinicityConsentReceipt = /* GraphQL */ `mutation CreateHighnoteFinicityConsentReceipt(
  $input: CreateFinicityConsentReceiptInput!
) {
  createHighnoteFinicityConsentReceipt(input: $input) {
    receipt {
      profile
      version
      receiptId
      receiptVersion
      customerId
      partnerId
      __typename
    }
    proof {
      signature
      keyId
      timestamp
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHighnoteFinicityConsentReceiptMutationVariables,
  APITypes.CreateHighnoteFinicityConsentReceiptMutation
>;
export const createFinicityCustomer = /* GraphQL */ `mutation CreateFinicityCustomer($input: CreateFinicityCustomerInput!) {
  createFinicityCustomer(input: $input) {
    id
    username
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFinicityCustomerMutationVariables,
  APITypes.CreateFinicityCustomerMutation
>;
export const deleteFinicityFinancialAccount = /* GraphQL */ `mutation DeleteFinicityFinancialAccount(
  $input: DeleteFinicityCustomerAccount!
) {
  deleteFinicityFinancialAccount(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteFinicityFinancialAccountMutationVariables,
  APITypes.DeleteFinicityFinancialAccountMutation
>;
export const createDwollaExchange = /* GraphQL */ `mutation CreateDwollaExchange($input: CreateDwollaExchangeInput!) {
  createDwollaExchange(input: $input)
}
` as GeneratedMutation<
  APITypes.CreateDwollaExchangeMutationVariables,
  APITypes.CreateDwollaExchangeMutation
>;
export const createDwollaFundingSource = /* GraphQL */ `mutation CreateDwollaFundingSource($input: CreateDwollaFundingSourceInput!) {
  createDwollaFundingSource(input: $input)
}
` as GeneratedMutation<
  APITypes.CreateDwollaFundingSourceMutationVariables,
  APITypes.CreateDwollaFundingSourceMutation
>;
export const createDwollaCustomer = /* GraphQL */ `mutation CreateDwollaCustomer($input: CreateDwollaCustomerInput!) {
  createDwollaCustomer(input: $input)
}
` as GeneratedMutation<
  APITypes.CreateDwollaCustomerMutationVariables,
  APITypes.CreateDwollaCustomerMutation
>;
export const createDwollaBusinessCustomer = /* GraphQL */ `mutation CreateDwollaBusinessCustomer(
  $input: CreateDwollaBusinessCustomerInput!
) {
  createDwollaBusinessCustomer(input: $input)
}
` as GeneratedMutation<
  APITypes.CreateDwollaBusinessCustomerMutationVariables,
  APITypes.CreateDwollaBusinessCustomerMutation
>;
export const deleteDwollaCustomerFundingSources = /* GraphQL */ `mutation DeleteDwollaCustomerFundingSources(
  $input: DeleteDwollaCustomerFundingSourcesInput!
) {
  deleteDwollaCustomerFundingSources(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteDwollaCustomerFundingSourcesMutationVariables,
  APITypes.DeleteDwollaCustomerFundingSourcesMutation
>;
export const createTransfer = /* GraphQL */ `mutation CreateTransfer($input: CreateTransferInput!) {
  createTransfer(input: $input) {
    success
    data
    errors
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTransferMutationVariables,
  APITypes.CreateTransferMutation
>;
export const getOrRefreshProcoreAccessToken = /* GraphQL */ `mutation GetOrRefreshProcoreAccessToken(
  $input: GetOrRefreshProcoreAccessTokenInput!
) {
  getOrRefreshProcoreAccessToken(input: $input) {
    access_token
    token_type
    expires_in
    refresh_token
    created_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetOrRefreshProcoreAccessTokenMutationVariables,
  APITypes.GetOrRefreshProcoreAccessTokenMutation
>;
export const createOrUpdateProcoreDirectCost = /* GraphQL */ `mutation CreateOrUpdateProcoreDirectCost(
  $input: CreateOrUpdateProcoreDirectCostInput!
) {
  createOrUpdateProcoreDirectCost(input: $input) {
    transactionId
    directCostId
    directCostLineItemId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrUpdateProcoreDirectCostMutationVariables,
  APITypes.CreateOrUpdateProcoreDirectCostMutation
>;
export const agaveLinkTokenCreate = /* GraphQL */ `mutation AgaveLinkTokenCreate {
  agaveLinkTokenCreate {
    link_token
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AgaveLinkTokenCreateMutationVariables,
  APITypes.AgaveLinkTokenCreateMutation
>;
export const agaveLinkTokenExchange = /* GraphQL */ `mutation AgaveLinkTokenExchange($input: AgaveAccountTokenInput!) {
  agaveLinkTokenExchange(input: $input) {
    account_token
    account_id
    connection {
      type
      __typename
    }
    created_at
    linked_company {
      id
      source_id
      name
      __typename
    }
    linked_user {
      id
      source_id
      name
      email
      __typename
    }
    reference_id
    source_system {
      name
      slug
      __typename
    }
    source_system_environment {
      name
      slug
      __typename
    }
    status
    supports_cross_account_uuid
    supports_cross_company_requests
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AgaveLinkTokenExchangeMutationVariables,
  APITypes.AgaveLinkTokenExchangeMutation
>;
export const agaveCreateExpense = /* GraphQL */ `mutation AgaveCreateExpense($input: CreateAgaveExpenseInput!) {
  agaveCreateExpense(input: $input) {
    ... on AgaveError {
      message
    }
    ... on CreateAgaveExpenseAPInvoice {
      id
      source_id
      amount
      amount_due
      ap_payment_ids
      attachments {
        id
        source_id
        name
        url
        source_create_time
        source_update_time
        __typename
      }
      batch
      batch_id
      contract {
        id
        type
        __typename
      }
      creator_id
      currency_code
      description
      discount_amount
      discount_date
      due_date
      issue_date
      line_items {
        id
        source_id
        amount
        cost_code_id
        cost_type_id
        customer_id
        description
        discount_amount
        item_id
        ledger_account_id
        project_id
        quantity
        retention_amount
        status
        sub_job_id
        tax_amount
        tax_code_id
        type
        unit_cost
        unit_of_measure
        vendor_id
        wbs_id
        source_create_time
        source_update_time
        __typename
      }
      misc_amount
      net_amount
      number
      project_id
      retention_amount
      retention_percent
      status
      subtotal_amount
      tax_amount
      total_amount
      type
      vendor_id
      source_create_time
      source_update_time
      source_data {
        path
        content_type
        data
        __typename
      }
    }
  }
}
` as GeneratedMutation<
  APITypes.AgaveCreateExpenseMutationVariables,
  APITypes.AgaveCreateExpenseMutation
>;
export const agaveAPInvoicesCreateExpense = /* GraphQL */ `mutation AgaveAPInvoicesCreateExpense(
  $input: AgaveAPInvoicesCreateExpenseInput!
) {
  agaveAPInvoicesCreateExpense(input: $input) {
    ... on AgaveError {
      message
    }
    ... on AgaveAPInvoicesPayload {
      id
      source_id
      amount
      amount_due
      ap_payment_ids
      attachments {
        id
        source_id
        name
        url
        source_create_time
        source_update_time
        __typename
      }
      batch
      batch_id
      contract {
        id
        type
        __typename
      }
      creator_id
      currency_code
      description
      discount_amount
      discount_date
      due_date
      issue_date
      line_items {
        id
        source_id
        amount
        cost_code_id
        cost_type_id
        customer_id
        description
        discount_amount
        item_id
        ledger_account_id
        project_id
        quantity
        retention_amount
        status
        sub_job_id
        tax_amount
        tax_code_id
        type
        unit_cost
        unit_of_measure
        vendor_id
        wbs_id
        source_create_time
        source_update_time
        __typename
      }
      misc_amount
      net_amount
      number
      project_id
      retention_amount
      retention_percent
      status
      subtotal_amount
      tax_amount
      total_amount
      type
      vendor_id
      source_create_time
      source_update_time
      source_data {
        path
        content_type
        data
        __typename
      }
    }
  }
}
` as GeneratedMutation<
  APITypes.AgaveAPInvoicesCreateExpenseMutationVariables,
  APITypes.AgaveAPInvoicesCreateExpenseMutation
>;
export const attachDirectCostReceipt = /* GraphQL */ `mutation AttachDirectCostReceipt($input: AttachDirectCostReceiptInput!) {
  attachDirectCostReceipt(input: $input) {
    directCostId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AttachDirectCostReceiptMutationVariables,
  APITypes.AttachDirectCostReceiptMutation
>;
export const createReimbursementDirectCost = /* GraphQL */ `mutation CreateReimbursementDirectCost(
  $input: CreateReimbursementDirectCostInput!
) {
  createReimbursementDirectCost(input: $input) {
    reimbursementTransactionId
    directCostId
    directCostLineItemId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReimbursementDirectCostMutationVariables,
  APITypes.CreateReimbursementDirectCostMutation
>;
export const completeFirstRunChecklist = /* GraphQL */ `mutation CompleteFirstRunChecklist($input: CompleteChecklistInput!) {
  completeFirstRunChecklist(input: $input) {
    paidolId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CompleteFirstRunChecklistMutationVariables,
  APITypes.CompleteFirstRunChecklistMutation
>;
export const checkAndUpdateChecklist = /* GraphQL */ `mutation CheckAndUpdateChecklist($input: CompleteChecklistInput!) {
  checkAndUpdateChecklist(input: $input) {
    paidolId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CheckAndUpdateChecklistMutationVariables,
  APITypes.CheckAndUpdateChecklistMutation
>;
export const completeFirstRunBanner = /* GraphQL */ `mutation CompleteFirstRunBanner($input: CompleteBannerInput!) {
  completeFirstRunBanner(input: $input) {
    userId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CompleteFirstRunBannerMutationVariables,
  APITypes.CompleteFirstRunBannerMutation
>;
export const checkAndUpdateBanner = /* GraphQL */ `mutation CheckAndUpdateBanner($input: CompleteBannerInput!) {
  checkAndUpdateBanner(input: $input) {
    userId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CheckAndUpdateBannerMutationVariables,
  APITypes.CheckAndUpdateBannerMutation
>;
export const attachSpendRulesToGroupCards = /* GraphQL */ `mutation AttachSpendRulesToGroupCards(
  $input: AttachSpendRulesToGroupCardsInput!
) {
  attachSpendRulesToGroupCards(input: $input) {
    cardGroupId
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AttachSpendRulesToGroupCardsMutationVariables,
  APITypes.AttachSpendRulesToGroupCardsMutation
>;
