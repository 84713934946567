import MaximumTransactionAmountSpendRule from './MaximumTransactionAmountSpendRule';
import ResponsiveButtonContainer from 'app/shared-components/layout/ResponsiveButtonContainer';
import SpendingByCategory from './CategorySpendRule';
import SpendingByCountry from './CountrySpendRule';
import ProgressButton from 'app/shared-components/ui/ProgressButton';
import * as yup from 'yup';
import { formatText } from 'util/sanitizeText';
import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChecklistRtl } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { MerchantSpendRule } from 'API';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, Button, InputAdornment, SxProps, TextField, Theme } from '@mui/material';

const schema = yup.object({
  name: yup.string().required('You must enter a spend rule name'),
  spendingByCategory: yup.array(),
  spendingByCountry: yup.array(),
  spendingByCategoryType: yup.string(),
  spendingByCountryType: yup.string(),
  maximumTransactionAmount: yup.number(),
});

export type EditSpendRuleFormValues = yup.InferType<typeof schema>;

export interface EditSpendRuleFormProps {
  selectedSpendRule?: MerchantSpendRule | undefined;
  highnoteRules?: Map<any, any>;
  loading?: boolean;
  onClose?: () => void;
  handleSkip?: () => void;
  onSubmitForm: (values: EditSpendRuleFormValues) => void;
  sx?: SxProps<Theme>;
}

function EditSpendRuleForm({
  selectedSpendRule,
  highnoteRules,
  loading,
  onClose,
  handleSkip,
  onSubmitForm,
  sx,
}: EditSpendRuleFormProps): JSX.Element {
  const { t } = useTranslation();

  const methods = useForm<EditSpendRuleFormValues>({
    mode: 'onChange',
    defaultValues: {
      name: selectedSpendRule?.name,
    },
    resolver: yupResolver(schema),
  });
  const { control, formState, getValues, setError } = methods;
  const { errors, isValid, isDirty } = formState;
  const buttonLabel = selectedSpendRule ? t('update') : t('save');

  const handleSubmit = useCallback(
    (onSubmit: (values: EditSpendRuleFormValues) => void) => () => onSubmit(getValues()),
    [getValues]
  );

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          ...sx,
        }}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              error={!!errors.name}
              helperText={errors?.name?.message}
              label={t('ruleName')}
              id="name"
              variant="outlined"
              required
              onBlur={(e) => field.onChange(formatText(e.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ChecklistRtl />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <SpendingByCountry control={control} selectedSpendRule={selectedSpendRule} />
        <SpendingByCategory control={control} selectedSpendRule={selectedSpendRule} />
        <MaximumTransactionAmountSpendRule selectedSpendRule={selectedSpendRule} />
      </Box>

      <ResponsiveButtonContainer>
        {onClose && <Button onClick={onClose}>{t('cancel')}</Button>}
        {handleSkip && (
          <Button onClick={handleSkip} variant="text" size="small">
            {t('illDoThisLater')}
          </Button>
        )}
        <ProgressButton
          size={onClose ? 'medium' : 'small'}
          loading={loading}
          onClick={handleSubmit(onSubmitForm)}
          disabled={!isValid || !isDirty || loading}
        >
          {buttonLabel}
        </ProgressButton>
      </ResponsiveButtonContainer>
    </FormProvider>
  );
}

export default EditSpendRuleForm;
