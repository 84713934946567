// import ProcoreUserAutocomplete from 'app/shared-components/forms/procore-user/ProcoreUserAutocomplete';
// import ProcoreProjectAutocomplete from 'app/shared-components/forms/procore-project/ProcoreProjectAutocomplete';
import states from 'util/states_titlecase.json';
import BudgetIcon from 'app/shared-components/icons/BudgetIcon';
import ProgressButton from 'app/shared-components/ui/ProgressButton';
import ResponsiveButtonContainer from 'app/shared-components/layout/ResponsiveButtonContainer';
import { formatText, formatEmail, sanitizePostalCode, sanitizeText, formatName } from 'util/sanitizeText';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { getMerchantSpendRules, resetSpendRulesSlice } from 'app/pages/store/spendRulesSlice';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { useAppDispatch, useAppSelector } from 'app/store';
import { addYears, startOfMonth } from 'date-fns';
import { selectSelectedGroup } from 'app/pages/store/groupsSlice';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserHasRole } from 'app/shared-components/auth/AuthProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  LocalShippingOutlined as LocalShippingOutlinedIcon,
  CreditCardOutlined as CreditCardOutlinedIcon,
  Groups as GroupsIcon,
  Rule as RuleIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Unstable_Grid2 as Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import {
  CardGroup,
  GetCardsPayload,
  GetProcoreProjectUsersPayload,
  HNCardFormFactor,
  MerchantSpendRule,
  Role,
} from 'API';
import {
  CARD_TYPES,
  FormType,
  NO_GROUP,
  NO_RULE,
  NO_SHIPPING,
  NewCardFormValues,
  SHIPPING_METHODS,
  schema,
  schemaValidationMessages,
} from './newCardUtils';

export interface NewCardFormProps {
  formType: FormType;
  loading: boolean;
  groups?: Array<CardGroup | undefined>;
  rules: MerchantSpendRule[];
  onSubmit: (values: NewCardFormValues) => void;
  onCancel: () => void;
  paymentCard?: GetCardsPayload;
  cancelText?: string;
}

function NewCardForm({
  formType,
  loading,
  groups,
  rules,
  onSubmit,
  onCancel,
  paymentCard,
  cancelText,
}: NewCardFormProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { selectedPaidol, selectedPaidolId: selectedCompany } = useAppSelector(selectUserCompanies);
  const userIsAdministrator = useUserHasRole(Role.ADMINISTRATOR);
  const selectedGroup = useAppSelector(selectSelectedGroup);
  const { t } = useTranslation();
  // const isConstructionType = useMemo(
  //   () => selectedPaidol?.isConstructionType ?? false,
  //   [selectedPaidol?.isConstructionType]
  // );

  const isAgaveClient = useMemo(
    () => selectedPaidol?.isAgaveClient ?? false,
    [selectedPaidol?.isAgaveClient]
  );

  const methods = useForm<NewCardFormValues>({
    mode: 'onBlur',
    defaultValues: {
      formType,
      cardName: paymentCard?.name || '',
      email: paymentCard?.cardholder?.email || '',
      // isConstructionType,
      isAgaveClient,
      project: undefined,
      user: paymentCard?.procoreEmployee
        ? {
            id: paymentCard?.procoreEmployee.id,
            name: paymentCard?.procoreEmployee.name,
            email_address: paymentCard?.procoreEmployee.email_address || undefined,
          }
        : undefined,
      cardGroupId: paymentCard?.cardGroup?.id || selectedGroup?.id || NO_GROUP,
      cardType:
        formType === FormType.OrderPhysical
          ? HNCardFormFactor.PHYSICAL
          : paymentCard?.formFactor
          ? paymentCard.formFactor
          : HNCardFormFactor.VIRTUAL,
      cardExpirationDate: paymentCard?.expirationDate ? new Date(paymentCard.expirationDate) : undefined,
      addressFirstName: '',
      addressLastName: '',
      addressZip: '',
      addressStreet1: '',
      addressStreet2: '',
      addressCity: '',
      addressState: '',
      requireSignature: false,
      shippingMethod: NO_SHIPPING,
      isMonthlyBudgetUnlimited: paymentCard?.monthlySpendLimit?.amount === undefined,
      isMaximumTransactionAmountUnlimited: paymentCard?.maximumTransactionAmount?.amount === undefined,
      monthlySpendLimit:
        (paymentCard?.monthlySpendLimit?.amount && paymentCard?.monthlySpendLimit?.amount / 100) || undefined,
      maximumTransactionAmount:
        (paymentCard?.maximumTransactionAmount?.amount &&
          paymentCard?.maximumTransactionAmount?.amount / 100) ||
        undefined,
      merchantSpendRuleId: paymentCard?.spxSpendRule?.id || NO_RULE,
      requireCardCode: false,
      // Trello card 489, temporarily removed this logic until we rework the spend controls
      // requireAddress: paymentCard === undefined || paymentCard?.requireAddress,
      requireAddress: false,
    },
    resolver: yupResolver(schema),
  });

  const { control, formState, getValues, handleSubmit, watch, setValue, clearErrors } = methods;
  const { errors } = formState;

  const [watchCardType, watchAddressZip] = watch(['cardType', 'addressZip']);

  const isCardTypePhysical = useMemo(() => watchCardType === HNCardFormFactor.PHYSICAL, [watchCardType]);

  const shouldDisplayMailing = useMemo(
    () => isCardTypePhysical && formType !== FormType.UpdateCard,
    [formType, isCardTypePhysical]
  );

  useEffect(() => {
    // Lazy value set since form is mounted before get rules request
    setValue('merchantSpendRuleId', paymentCard?.spxSpendRule?.id);
    selectedGroup?.id && setValue('cardGroupId', selectedGroup.id);
  }, [paymentCard?.spxSpendRule?.id, selectedGroup?.id, setValue]);

  useEffect(() => {
    dispatch(
      getMerchantSpendRules({
        paidolId: selectedCompany,
        shouldLoadDetails: false,
      })
    );

    return () => {
      dispatch(resetSpendRulesSlice());
    };
  }, [dispatch, selectedCompany]);

  const onClickSave: SubmitHandler<NewCardFormValues> = (data) => {
    return onSubmit(data);
  };

  const onProcoreUserSelected = (user: GetProcoreProjectUsersPayload | null) => {
    setValue('email', user ? (user.email_address ? user.email_address : '') : '');
    setValue('cardName', user ? user.name : '');
  };

  const unlimitedSpendingWatch = watch('isMonthlyBudgetUnlimited');
  const unlimitedTransactionWatch = watch('isMaximumTransactionAmountUnlimited');

  return (
    <FormProvider {...methods}>
      {/* First Col (Basics) */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {/* Basics */}
        <Grid xs={12}>
          <Box display="flex" alignItems="center">
            <CreditCardOutlinedIcon sx={{ width: 26, height: 26, mr: 1, color: 'primary.main' }} />
            <Typography variant="h4" color="primary.main">
              {t('basics')}
            </Typography>
          </Box>
        </Grid>

        {
          // (!isConstructionType || isAgaveClient) &&
          <>
            {/* Card name description */}
            <Grid xs={12}>
              <Typography variant="medium">
                <b>{t('cardholderPrintedOnPhysicalCards')} </b>
                {t('cardholderNameHelp')}
              </Typography>
            </Grid>

            {/* Card name */}
            <Grid xs={12}>
              <Controller
                name="cardName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    error={!!errors.cardName}
                    // helperText={errors?.cardName?.message}
                    helperText={schemaValidationMessages.cardName}
                    label={t('cardholder')}
                    id="cardName"
                    variant="outlined"
                    required
                    fullWidth
                    onBlur={(e) => field.onChange(sanitizeText(e.target.value))}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CreditCardOutlinedIcon />
                        </InputAdornment>
                      ),
                      inputProps: {
                        style: { paddingLeft: '8px' },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </>
        }
        {
          // (!isConstructionType || isAgaveClient) &&
          <>
            {/* Email description */}
            <Grid xs={12}>
              <Typography variant="medium">
                <b>{t('enterCardholdersEmail')}</b>
              </Typography>
            </Grid>

            {/* Email */}
            <Grid xs={12} xl={6}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    label={t('cardholdersEmail')}
                    id="email"
                    variant="outlined"
                    required
                    fullWidth
                    disabled={formType === FormType.OrderPhysical || formType === FormType.UpdateCard}
                    onBlur={(e) => field.onChange(formatEmail(e.target.value))}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleOutlinedIcon />
                        </InputAdornment>
                      ),
                      inputProps: {
                        style: { paddingLeft: '8px' },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </>
        }

        {
          // isConstructionType && !isAgaveClient && (
          //   <>
          //     {/* Project */}
          //     {formType === FormType.IssueCard && (
          //       <Grid xs={12}>
          //         <ProcoreProjectAutocomplete />
          //       </Grid>
          //     )}
          //     {/* User */}
          //     <Grid xs={12} xl={6}>
          //       <ProcoreUserAutocomplete
          //         label={t('cardholder')}
          //         disabled={formType !== FormType.IssueCard}
          //         onUserChange={onProcoreUserSelected}
          //       />
          //     </Grid>
          //   </>
          // )
        }

        {/* Card type */}
        <Grid xs={6}>
          <Controller
            name="cardType"
            control={control}
            render={({ field }) => (
              <FormControl
                fullWidth
                required
                error={!!errors.cardType}
                disabled={formType === FormType.OrderPhysical || formType === FormType.UpdateCard}
              >
                <InputLabel id="cardType-label">{t('cardType')}</InputLabel>
                <Select
                  {...field}
                  id="cardType"
                  labelId="cardType-label"
                  label={t('cardType')}
                  startAdornment={
                    <InputAdornment position="start">
                      <CreditCardOutlinedIcon />
                    </InputAdornment>
                  }
                >
                  {Object.entries(CARD_TYPES).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{t('virtualCardsUsage')}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        {/* Assign to group */}
        <Grid xs={6}>
          <Controller
            name="cardGroupId"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth disabled={formType === FormType.OrderPhysical}>
                <InputLabel id="cardGroupId-label">{t('assignToGroup')}</InputLabel>
                <Select
                  {...field}
                  id="cardGroupId"
                  labelId="cardGroupId-label"
                  label={t('assignToGroup')}
                  startAdornment={
                    <InputAdornment position="start">
                      <GroupsIcon />
                    </InputAdornment>
                  }
                >
                  {(formType === FormType.UpdateCard || userIsAdministrator) && (
                    <MenuItem value={NO_GROUP}>{t('noGroup')}</MenuItem>
                  )}
                  {groups
                    ?.filter((group): group is CardGroup => !!group)
                    .map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        {/* Card expiration date */}
        <Grid>
          <Controller
            name="cardExpirationDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  {...field}
                  views={['year', 'month']}
                  label={t('cardExpirationDate')}
                  value={field.value || null}
                  onChange={(newValue) => field.onChange(newValue ? startOfMonth(newValue) : null)}
                  minDate={startOfMonth(new Date())}
                  maxDate={addYears(startOfMonth(new Date()), 4)}
                  format="MM/yyyy"
                  disabled={formType === FormType.OrderPhysical || formType === FormType.UpdateCard}
                  sx={{ width: '186px' }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      required: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
      </Grid>

      {/* Second Col (Mailing) */}
      {shouldDisplayMailing && (
        <Grid container spacing={2} sx={{ pb: 2, mt: 1 }}>
          {/* Physical card mailing */}
          <Grid xs={12}>
            <Box display="flex" alignItems="center">
              <LocalShippingOutlinedIcon sx={{ width: 26, height: 26, mr: 1, color: 'primary.main' }} />
              <Typography variant="h4" color="primary.main">
                {t('physicalCardMailing')}
              </Typography>
            </Box>
          </Grid>

          {/* Address */}
          <Grid xs={12}>
            <Typography variant="medium">
              <b>{t('address')}</b>
            </Typography>
          </Grid>

          {/* First name */}
          <Grid xs={6}>
            <Controller
              name="addressFirstName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  error={!!errors.addressFirstName}
                  // helperText={errors?.addressFirstName?.message}
                  label={t('firstName')}
                  id="addressFirstName"
                  variant="outlined"
                  required
                  fullWidth
                  onBlur={(e) => field.onChange(formatName(e.target.value))}
                />
              )}
            />
          </Grid>

          {/* Last name */}
          <Grid xs={6}>
            <Controller
              name="addressLastName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  error={!!errors.addressLastName}
                  // helperText={errors?.addressLastName?.message}
                  label={t('lastName')}
                  id="addressLastName"
                  variant="outlined"
                  required
                  fullWidth
                  onBlur={(e) => field.onChange(formatName(e.target.value))}
                />
              )}
            />
          </Grid>

          {/* Street 1 */}
          <Grid xs={12} xl={6}>
            <Controller
              name="addressStreet1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  error={!!errors.addressStreet1}
                  // helperText={errors?.addressStreet1?.message}
                  label="Street 1"
                  id="addressStreet1"
                  variant="outlined"
                  required
                  fullWidth
                  //disabled={!watchAddressZip}
                  onBlur={(e) => field.onChange(formatText(e.target.value))}
                />
              )}
            />
          </Grid>

          {/* Street 2 */}
          <Grid xs={12} xl={6}>
            <Controller
              name="addressStreet2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  error={!!errors.addressStreet2}
                  // helperText={errors?.addressStreet2?.message}
                  label="Street 2"
                  id="addressStreet2"
                  variant="outlined"
                  fullWidth
                  disabled={!getValues('addressZip')}
                  onBlur={(e) => field.onChange(formatText(e.target.value))}
                />
              )}
            />
          </Grid>

          {/* City */}
          <Grid xs={12} xl={4}>
            <Controller
              name="addressCity"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  error={!!errors.addressCity}
                  // helperText={errors?.addressCity?.message}
                  label={t('city')}
                  id="addressCity"
                  variant="outlined"
                  required
                  fullWidth
                  disabled={!getValues('addressZip')}
                  onBlur={(e) => field.onChange(formatText(e.target.value))}
                />
              )}
            />
          </Grid>

          {/* State */}
          <Grid xs={6} xl={4}>
            <Controller
              name="addressState"
              control={control}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  required
                  disabled={!getValues('addressZip')}
                  error={!!errors.addressState}
                >
                  <InputLabel id="addressState-label">{`State`}</InputLabel>
                  <Select {...field} id="addressState" labelId="addressState-label" label="State">
                    {states.map((state) => (
                      <MenuItem key={state.abbreviation} value={state.abbreviation}>
                        {state.name} ({state.abbreviation})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          {/* ZIP */}
          <Grid xs={6} xl={4}>
            <Controller
              name="addressZip"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  error={!!errors.addressZip}
                  // helperText={errors?.addressZip?.message}
                  label={t('zip')}
                  id="addressZip"
                  variant="outlined"
                  required
                  fullWidth
                  onBlur={(e) => field.onChange(sanitizePostalCode(e.target.value))}
                />
              )}
            />
          </Grid>

          {/* Delivery */}
          <Grid xs={12} sx={{ mt: 2 }}>
            <Typography variant="medium">
              <b>{t('delivery')}</b>
            </Typography>
          </Grid>

          {/* Require signature */}
          <Grid xs={12}>
            <Controller
              control={control}
              name="requireSignature"
              render={({ field }) => {
                return (
                  <FormControlLabel
                    control={<Switch {...field} />}
                    checked={!!field.value}
                    label={t('requireSignatureForDelivery')}
                  />
                );
              }}
            />
          </Grid>

          {/* Shipping method */}
          <Grid xs={12}>
            <Controller
              name="shippingMethod"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth required error={!!errors.shippingMethod}>
                  <InputLabel id="shippingMethod-label">{t('shippingMethod')}</InputLabel>
                  <Select
                    {...field}
                    id="shippingMethod"
                    labelId="shippingMethod-label"
                    label={t('shippingMethod')}
                    startAdornment={
                      <InputAdornment position="start">
                        <LocalShippingOutlinedIcon />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={NO_SHIPPING}>{`No method selected`}</MenuItem>
                    {Object.entries(SHIPPING_METHODS).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      )}

      {/* Third Col (Rules and limits) */}
      <Grid xs={12} md={shouldDisplayMailing ? 4 : 6}>
        <Grid container spacing={2} sx={{ borderRadius: '8px', py: 1, mb: 0.5 }}>
          {/* Monthly Budget */}
          <Grid xs={4}>
            <Box>
              <Controller
                name="monthlySpendLimit"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="monthlySpendLimit"
                    type="number"
                    label={t('monthlySpendLimit')}
                    required={!unlimitedSpendingWatch}
                    value={!unlimitedSpendingWatch ? field.value ?? '' : ''}
                    error={!unlimitedSpendingWatch && !!errors.monthlySpendLimit}
                    helperText={errors?.monthlySpendLimit?.message}
                    placeholder={unlimitedSpendingWatch ? 'No limit' : ''}
                    variant="outlined"
                    fullWidth
                    disabled={
                      formType === FormType.OrderPhysical || !userIsAdministrator || unlimitedSpendingWatch
                    }
                    InputProps={{
                      inputProps: { min: 0 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <BudgetIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Box sx={{ p: 1 }}>
                <Controller
                  control={control}
                  name="isMonthlyBudgetUnlimited"
                  render={({ field }) => (
                    <FormControlLabel
                      label={t('unlimited')}
                      control={
                        <Switch
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            const isUnlimited = e.target.checked;
                            setValue('isMonthlyBudgetUnlimited', isUnlimited);
                            setValue(
                              'monthlySpendLimit',
                              isUnlimited ? undefined : getValues('monthlySpendLimit')
                            );
                            if (isUnlimited) {
                              clearErrors('monthlySpendLimit');
                            }
                          }}
                        />
                      }
                    />
                  )}
                />
              </Box>
            </Box>
          </Grid>

          {/* Maximum transaction amount */}
          <Grid xs={4}>
            <Box>
              <Controller
                name="maximumTransactionAmount"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="maximumTransactionAmount"
                    type="number"
                    label="Maximum transaction amount"
                    required={!unlimitedTransactionWatch}
                    value={!unlimitedTransactionWatch ? field.value ?? '' : ''}
                    error={!unlimitedTransactionWatch && !!errors.maximumTransactionAmount}
                    helperText={errors?.maximumTransactionAmount?.message}
                    placeholder={unlimitedTransactionWatch ? 'No maximum' : ''}
                    variant="outlined"
                    fullWidth
                    disabled={
                      formType === FormType.OrderPhysical || !userIsAdministrator || unlimitedTransactionWatch
                    }
                    InputProps={{
                      inputProps: { min: 0 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <BudgetIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Box sx={{ p: 1 }}>
                <Controller
                  control={control}
                  name="isMaximumTransactionAmountUnlimited"
                  render={({ field }) => (
                    <FormControlLabel
                      label={t('unlimitedTransAmount')}
                      control={
                        <Switch
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            const isUnlimited = e.target.checked;
                            setValue('isMaximumTransactionAmountUnlimited', isUnlimited);
                            setValue(
                              'maximumTransactionAmount',
                              isUnlimited ? undefined : getValues('maximumTransactionAmount')
                            );
                            if (isUnlimited) {
                              clearErrors('maximumTransactionAmount');
                            }
                          }}
                        />
                      }
                    />
                  )}
                />
              </Box>
            </Box>
          </Grid>

          {/* Spend rule */}
          <Grid xs={4}>
            <Controller
              name="merchantSpendRuleId"
              control={control}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  error={!!errors.merchantSpendRuleId}
                  disabled={formType === FormType.OrderPhysical || !userIsAdministrator}
                >
                  <InputLabel id="merchantSpendRuleId-label">{`Spend rule`}</InputLabel>
                  <Select
                    {...field}
                    id="merchantSpendRuleId"
                    labelId="merchantSpendRuleId-label"
                    label="Spend rule"
                    startAdornment={
                      <InputAdornment position="start">
                        <RuleIcon />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={NO_RULE}>{`No rule applied`}</MenuItem>
                    {rules.map((rule) => (
                      <MenuItem key={rule.id} value={rule.id}>
                        {rule.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          {/* Trello card: 489
                We re currently creating a new spend control every time a card is issued which needs to be reworked.
                Lets remove the option to add the “require address” spend control from the issue card side sheet until we rework the spend controls. */}

          {/* Spend rule description */}
          {/* <Grid xs={12}>
                <Typography variant="small">{`Create new spend rule under Spend rules tab`}</Typography>
              </Grid> */}

          {/* Require address */}
          {/* <Grid xs={12}>
                <Controller
                  control={control}
                  name="requireAddress"
                  render={({ field }) => {
                    return (
                      <FormControlLabel
                        control={<Switch {...field} />}
                        disabled={formType === FormType.OrderPhysical || !userIsAdministrator}
                        label={
                          <>
                            {`Require address`}
                            <br />
                            <Typography variant="small">
                              {`Require Address Verification Result (AVS) for purchases`}
                            </Typography>
                          </>
                        }
                      />
                    );
                  }}
                />
              </Grid> */}
        </Grid>
      </Grid>

      <ResponsiveButtonContainer>
        <>
          {cancelText ? (
            <Button onClick={onCancel} variant="text" size="small" disabled={loading}>
              {cancelText}
            </Button>
          ) : (
            <Button onClick={onCancel} variant="outlined" size="small" disabled={loading}>
              {t('cancel')}
            </Button>
          )}
          <ProgressButton
            loading={loading}
            disabled={loading}
            size="small"
            onClick={handleSubmit(onClickSave)}
          >
            {formType === FormType.UpdateCard ? t('saveChanges') : t('issueCard')}
          </ProgressButton>
        </>
      </ResponsiveButtonContainer>
    </FormProvider>
  );
}

export default NewCardForm;
