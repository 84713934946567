import { LoadingButton } from '@mui/lab';
import { Grid, Paper, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { captureException } from '@sentry/react';
import {
  issueFinancialAccountForApplication,
  selectIssueFinancialAccountForApplicationLoading,
  selectReviewOnboardSlice,
} from 'app/pages/store/reviewOnboardSlice';
import { updatePaidolUsers } from 'app/services/paidolUserService';
import { useIsEnabled } from 'app/shared-components/feature-flags/FeatureFlagProvider';
import { useAppDispatch, useAppSelector } from 'app/store';
import { getUserCompanies, selectUserCompanies, setSelectedPaidolId } from 'app/store/userCompaniesSlice';
import { ReactComponent as PCLady } from 'assets/PurchaseCardLady.svg';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Permission(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { integration } = useAppSelector(selectReviewOnboardSlice);

  const isWaitingOnApplication = Boolean(!integration?.isApplicationAccepted);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();
  const { paidolUsers } = useAppSelector(selectUserCompanies);

  const { 'firstRunStateFlags.isStepperEnabled': isStepperEnabled } = useIsEnabled([
    { featureName: 'firstRunStateFlags', flagName: 'isStepperEnabled' },
  ]);

  useEffect(() => {
    if (integration?.userId) {
      dispatch(getUserCompanies(integration?.userId));
      dispatch(setSelectedPaidolId(integration?.paidolId));
    }
  }, [dispatch, integration?.paidolId, integration?.userId]);

  const selectedPaidolUser = useMemo(
    () => paidolUsers.find((user) => user.paidol?.id === integration?.paidolId),
    [paidolUsers, integration?.paidolId]
  );

  const onSubmitFinancialAccountForApplication = () => {
    if (!integration) return;

    const { accountHolderCardProductApplicationId: applicationId, legalBusinessName: name } = integration;

    if (applicationId && name) {
      setIsButtonDisabled(true);
      dispatch(
        issueFinancialAccountForApplication({
          integration,
          applicationId,
          name,
        })
      )
        .then(() => {
          // This update is needed to trigger the PaidolUserTrigger and create the auth user for the first user after the financial account is available
          !selectedPaidolUser?.authorizedUserApplicationId &&
            selectedPaidolUser?.id &&
            updatePaidolUsers({ input: { id: selectedPaidolUser.id, authorizedUserApplicationId: '' } });
          enqueueSnackbar('Financial account issued successfully', { variant: 'success' });
        })
        .catch((err) => {
          captureException(err);
          enqueueSnackbar('An error has occurred: ' + err, { variant: 'error' });
          setIsButtonDisabled(false);
        });
    }
  };

  useEffect(() => {
    if (!integration?.financialAccountId) return;
    if (isStepperEnabled) {
      navigate('/review/onboard', { replace: true });
    } else {
      navigate('/overview', { replace: true });
    }
  }, [integration?.financialAccountId, isStepperEnabled, navigate]);

  return (
    <Box>
      <Typography
        variant="h1"
        sx={{
          mt: 2,
          mb: 1,
          color: theme.palette.primary.main,
          mx: 2,
        }}
      >
        {t('permissions.welcome')}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={5}>
          <Box
            sx={{
              paddingTop: 20,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <PCLady />
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              paddingTop: 20,
              flexDirection: 'column',
            }}
          >
            <Paper
              elevation={4}
              style={{
                padding: 12,
                display: 'stack',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: '300px',
              }}
            >
              {isWaitingOnApplication && <PlaceholderText />}
              {!isWaitingOnApplication && (
                <ApplicationForm
                  onSubmitApplication={onSubmitFinancialAccountForApplication}
                  isButtonDisabled={isButtonDisabled}
                />
              )}
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

function PlaceholderText(): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Typography
        variant="h3"
        sx={{
          m: 2,
          color: theme.palette.primary.main,
        }}
      >
        {t('permissions.appPendingTitle')}
      </Typography>
      <Box
        sx={{
          m: 2,
        }}
      >
        <Typography variant="large">{t('permissions.appPendingBody')}</Typography>
      </Box>
    </>
  );
}

export interface ApplicationFormProps {
  onSubmitApplication: () => void;
  isButtonDisabled: boolean;
}

function ApplicationForm({ onSubmitApplication, isButtonDisabled }: ApplicationFormProps): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLoading = useAppSelector(selectIssueFinancialAccountForApplicationLoading);

  return (
    <>
      <Typography
        variant="h3"
        sx={{
          m: 2,
          color: theme.palette.primary.main,
        }}
      >
        {t('permissions.appFormTitle')}
      </Typography>
      <Box
        sx={{
          m: 2,
        }}
      >
        <Typography variant="large">{t('permissions.appFormBody')}</Typography>
      </Box>

      <Box
        sx={{
          m: 2,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        }}
      >
        <LoadingButton
          disabled={isButtonDisabled}
          loading={isLoading}
          variant="contained"
          onClick={() => {
            onSubmitApplication();
          }}
        >
          {t('permissions.appFormButton')}
        </LoadingButton>
      </Box>
    </>
  );
}

export default Permission;
