import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  ListPaidolUsersQuery,
  ListPaidolUsersQueryVariables,
  ListUsersQuery,
  PaidolUser,
  RemoveUserInput,
  UpdatePaidolUserMutation,
  UpdatePaidolUserMutationVariables,
} from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import { removeUser, updatePaidolUser } from 'graphql/mutations';

const listPaidolUsers = /* GraphQL */ `
  query listPaidolUsers($filter: ModelPaidolUserFilterInput, $limit: Int, $nextToken: String) {
    listPaidolUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        allowedJobCodes
        user {
          id
          username
          email
          first_name
          last_name
          picture
          is_speedster
          birthdate
          address
          phone_number
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const listAllPaidolUsers = async (input: ListPaidolUsersQueryVariables) => {
  return (
    API.graphql(graphqlOperation(listPaidolUsers, input)) as Promise<GraphQLResult<ListPaidolUsersQuery>>
  ).then((result) => {
    return result.data?.listPaidolUsers?.items as PaidolUser[];
  });
};

export const updatePaidolUsers = async (input: UpdatePaidolUserMutationVariables) => {
  return (
    API.graphql(graphqlOperation(updatePaidolUser, input)) as Promise<GraphQLResult<UpdatePaidolUserMutation>>
  ).then((result) => {
    return result.data?.updatePaidolUser;
  });
};

export const deletePaidolUser = async (input: RemoveUserInput) => {
  return API.graphql(graphqlOperation(removeUser, { input })) as Promise<GraphQLResult<ListUsersQuery>>;
};
