import styled from '@emotion/styled';
import CardImageBlue from './assets/card-image.svg';
import CardImageWhite from './assets/card-image-white.svg';
import Box from '@mui/material/Box';

export const CardWrapper = styled(Box)<{ formFactor: string }>`
  display: flex;
  position: relative;
  flex-direction: column;
  left: 0px;
  top: 0px;
  border-radius: 20px;
  background-image: ${({ formFactor }) =>
    formFactor === 'VIRTUAL' ? `url(${CardImageWhite})` : `url(${CardImageBlue})`};
  background-size: cover;
  box-shadow: 7px 6px 12px -4px #acacac;
  max-height: 275px;

  @keyframes pulseanim {
    0% {
      transform: scale(0);
      opacity: 0.8;
    }
    100% {
      transform: scale(6);
      opacity: 0;
    }
  }

  .animate {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    height: 100%;
    width: 100%;

    .over {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: #ffff;
      opacity: 0.8;
      filter: blur(20px);
    }

    .pulse {
      background-color: #dddddd;
      animation: pulseanim 1.2s ease-in-out infinite;
      height: 4rem;
      width: 4rem;
      border-radius: 10rem;
    }
  }
  #cardNumber,
  #cardNumber > iframe {
    height: 36px;
    width: 286px;
    margin-left: -2px;
  }

  #expirationDate,
  #expirationDate > iframe,
  #cvv,
  #cvv > iframe {
    height: 16px;
    width: 60px;
  }
`;
