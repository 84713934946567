import theme from 'app/theme';
import Currency from 'app/shared-components/util/Currency';
import ResponsiveTable from 'app/shared-components/layout/table/ResponsiveTable';
import PaymentCardUsers from 'app/shared-components/paymentCard/PaymentCardUsers';
import ResponsiveTableHead from 'app/shared-components/layout/table/ResponsiveTableHead';
import { StyledTableRow } from 'app/pages/overview/tabs/transactions/style';
import { useTranslation } from 'react-i18next';
import { useObjectsTranslation } from 'app/hooks/useObjectsTranslation';
import { Box, Fade, TableCell, Tooltip, Typography } from '@mui/material';
import {
  PaidolUserToHighnotePaymentCard,
  HighnoteTransactionStatus,
  HighnoteTransaction,
  HNAmount,
} from 'API';
export interface Props {
  loading: boolean;
  transactions: Array<HighnoteTransaction>;
  cardUsers?: Array<PaidolUserToHighnotePaymentCard>;
}

const CardTransactionsTable = ({ transactions, cardUsers }: Props) => {
  const { t } = useTranslation();

  const TABLE_HEAD_COLUMNS = [
    {
      id: 'Card User',
      label: t('cardUser'),
      align: 'left' as const,
    },
    {
      id: 'Merchant',
      label: t('merchant'),
      align: 'left' as const,
    },
    {
      id: 'Amount',
      label: t('amount'),
      align: 'right' as const,
    },
    {
      id: 'Date',
      label: t('date'),
      align: 'left' as const,
    },
  ];

  const tableHeadColumns = useObjectsTranslation(TABLE_HEAD_COLUMNS);

  if (transactions.length === 0) {
    return (
      <Fade in>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2,
            p: { xs: 2 },
          }}
        >
          <Typography color="textSecondary" variant="h5">
            {t('noTransactionsForCard')}
          </Typography>
        </Box>
      </Fade>
    );
  }

  const getAmountCell = (status: HighnoteTransactionStatus, amount: HNAmount) => {
    if (!amount.currencyCode || !amount.value) return;

    return status === HighnoteTransactionStatus.CLEARED || status === HighnoteTransactionStatus.APPROVED ? (
      <Currency value={amount.value} currency={amount.currencyCode} />
    ) : (
      <Tooltip title="Chargeback" placement="top-start" arrow>
        <Typography fontSize={12} fontWeight="500" color={'error.main'}>
          -
          <Currency value={amount.value} currency={amount.currencyCode} />
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ResponsiveTable TableHead={<ResponsiveTableHead columns={tableHeadColumns} align="center" />}>
      {transactions.map((transaction) => {
        const isComplete = transaction.status !== HighnoteTransactionStatus.APPROVED;

        return (
          <StyledTableRow
            key={transaction.transactionId}
            color={isComplete ? theme.palette.text.secondary : theme.palette.secondary.dark}
          >
            <TableCell align="left" sx={{ fontWeight: '500' }}>
              <PaymentCardUsers users={cardUsers} />
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: '500' }}>
              {transaction.merchantName}
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: '500' }}>
              {getAmountCell(transaction.status, transaction.approvedAmount)}
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: '500' }}>
              {isComplete ? new Date(transaction.transactionDate).toLocaleDateString() : t('pending')}
            </TableCell>
          </StyledTableRow>
        );
      })}
    </ResponsiveTable>
  );
};
export default CardTransactionsTable;
