import {
  HighnotePaymentCard,
  HNAmountLimitSpendRule,
  HNCardFormFactor,
  HNCVVSpendRule,
  HNPaymentCard,
  HNPaymentCardShippingMethod,
  HNStreetAddressSpendRule,
  MerchantSpendRule,
} from 'API';
import { addYears, startOfMonth } from 'date-fns';
import * as yup from 'yup';

export const NO_GROUP = 'NO_GROUP';
export const NO_RULE = 'NO_RULE';
export const NO_SHIPPING = 'NO_SHIPPING';

export enum FormType {
  OrderPhysical = 'ORDER_PHYSICAL',
  IssueCard = 'ISSUE_CARD',
  UpdateCard = 'UPDATE_CARD',
}

export const CARD_TYPES: Record<HNCardFormFactor, string> = {
  [HNCardFormFactor.PHYSICAL]: 'Physical card',
  [HNCardFormFactor.VIRTUAL]: 'Virtual card',
};

export const SHIPPING_METHODS: { [K in HNPaymentCardShippingMethod]?: string } = {
  [HNPaymentCardShippingMethod.USPS_GROUND]: '(USPS) 2-8 business days',
  [HNPaymentCardShippingMethod.USPS_PRIORITY]: '(USPS) 1-3 business days',
  [HNPaymentCardShippingMethod.USPS_EXPRESS]: '(USPS) Next businesss day-2 days',
  [HNPaymentCardShippingMethod.UPS_GROUND]: '(UPS) 1-5 business days',
  [HNPaymentCardShippingMethod.UPS_SECOND_DAY]: '(UPS) 2 business days',
  [HNPaymentCardShippingMethod.UPS_NEXT_DAY]: '(UPS) Next business day',
};

export const schemaValidationMessages = {
  cardName: `Must be between 4 and 23 characters`,
  email: 'Please enter a valid email address',
  project: 'You must select a project',
  user: {
    required: 'You must select a user',
    email: 'User must have an email',
  },
  cardExpirationDate: {
    min: 'Must be higher than current date',
    max: 'Cannot be higher than 4 years from now',
  },
};

export const schema = yup.object({
  formType: yup.mixed<FormType>(),
  cardName: yup
    .string()
    .required()
    .when(['cardType', 'formType'], {
      is: (cardType: string, formType: FormType) =>
        formType === FormType.UpdateCard && cardType === HNCardFormFactor.PHYSICAL,
      then: (schema) => schema,
      otherwise: (schema) =>
        schema
          .test('custom', schemaValidationMessages.cardName, (value) => {
            return value !== undefined && value.length >= 4 && value.length <= 23;
          })
          .required(schemaValidationMessages.cardName),
    }),

  email: yup.string().email(schemaValidationMessages.email).required(schemaValidationMessages.email),
  isConstructionType: yup.bool(),
  isAgaveClient: yup.bool(),
  project: yup.object().when(['isConstructionType', 'isAgaveClient', 'formType'], {
    is: (isConstructionType: boolean, isAgaveClient: boolean, formType: FormType) =>
      isConstructionType === true && isAgaveClient === false && formType === FormType.IssueCard,
    then: yup.object().nullable().required(schemaValidationMessages.project),
  }),
  user: yup
    .object({
      id: yup.number(),
      name: yup.string(),
      email_address: yup.string(),
    })
    .when(['isConstructionType', 'isAgaveClient', 'formType'], {
      is: (isConstructionType: boolean, isAgaveClient: boolean, formType: FormType) =>
        isConstructionType === true && isAgaveClient === false && formType === FormType.IssueCard,
      then: (schema) =>
        schema
          .shape({
            id: yup.number(),
            name: yup.string(),
            email_address: yup.string().email().required(schemaValidationMessages.user.email),
          })
          .nullable()
          .required(schemaValidationMessages.user.required),
    }),
  cardGroupId: yup.string(),
  cardType: yup.string().required(),
  cardExpirationDate: yup
    .date()
    .typeError('')
    .min(startOfMonth(new Date()), schemaValidationMessages.cardExpirationDate.min)
    .max(addYears(startOfMonth(new Date()), 4), schemaValidationMessages.cardExpirationDate.max)
    .required(''),

  addressFirstName: yup.string().when(['cardType', 'formType'], {
    is: (cardType: string, formType: FormType) =>
      formType !== FormType.UpdateCard && cardType === HNCardFormFactor.PHYSICAL,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema,
  }),
  addressLastName: yup.string().when(['cardType', 'formType'], {
    is: (cardType: string, formType: FormType) =>
      formType !== FormType.UpdateCard && cardType === HNCardFormFactor.PHYSICAL,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema,
  }),
  addressZip: yup.string().when(['cardType', 'formType'], {
    is: (cardType: string, formType: FormType) =>
      formType !== FormType.UpdateCard && cardType === HNCardFormFactor.PHYSICAL,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema,
  }),
  addressStreet1: yup.string().when(['cardType', 'formType'], {
    is: (cardType: string, formType: FormType) =>
      formType !== FormType.UpdateCard && cardType === HNCardFormFactor.PHYSICAL,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema,
  }),
  addressStreet2: yup.string(),
  addressCity: yup.string().when(['cardType', 'formType'], {
    is: (cardType: string, formType: FormType) =>
      formType !== FormType.UpdateCard && cardType === HNCardFormFactor.PHYSICAL,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema,
  }),
  addressState: yup.string().when(['cardType', 'formType'], {
    is: (cardType: string, formType: FormType) =>
      formType !== FormType.UpdateCard && cardType === HNCardFormFactor.PHYSICAL,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema,
  }),
  requireSignature: yup.bool().required(),
  shippingMethod: yup
    .mixed<typeof NO_SHIPPING | HNPaymentCardShippingMethod>()
    .when(['cardType', 'formType'], {
      is: (cardType: string, formType: FormType) =>
        formType !== FormType.UpdateCard && cardType === HNCardFormFactor.PHYSICAL,
      then: (schema) => schema.oneOf(Object.values(HNPaymentCardShippingMethod)).required(),
      otherwise: (schema) => schema,
    }),
  isMonthlyBudgetUnlimited: yup.bool().required(),
  isMaximumTransactionAmountUnlimited: yup.bool().required(),

  monthlySpendLimit: yup
    .number()
    .min(0, 'Monthly spend limit must be 0 or higher')
    .nullable()
    .when('isMonthlyBudgetUnlimited', {
      is: true,
      then: (schema) => schema.transform(() => undefined).notRequired(),
      otherwise: (schema) =>
        schema
          .required('Monthly spend limit is required when not unlimited')
          .transform((value, originalValue) => (originalValue === '' ? undefined : value)),
    }),

  maximumTransactionAmount: yup
    .number()
    .min(0, 'Maximum transaction amount must be 0 or higher')
    .nullable()
    .when('isMaximumTransactionAmountUnlimited', {
      is: true,
      then: (schema) => schema.transform(() => undefined).notRequired(),
      otherwise: (schema) =>
        schema
          .required('Maximum transaction amount must be 0 or higher')
          .transform((value, originalValue) => (originalValue === '' ? undefined : value)),
    }),
  merchantSpendRuleId: yup.string(),
  requireCardCode: yup.bool().required(),
  requireAddress: yup.bool().required(),
});

export type NewCardFormValues = yup.InferType<typeof schema>;

export interface SelectedPaymentCardProps {
  card: HNPaymentCard;
  details?: HighnotePaymentCard;
}

export const getAttachedAmountSpendRuleFromPaymentCard = (paymentCard?: SelectedPaymentCardProps) => {
  const filteredRules =
    paymentCard?.card.attachedSpendRules?.edges?.filter(
      (spendRule) => (spendRule.node?.__typename as string) === 'HNAmountLimitSpendRule'
    ) || [];

  return filteredRules.length > 0
    ? (filteredRules.at(0)?.node as unknown as HNAmountLimitSpendRule)
    : undefined;
};

export const getAttachedAmountVelocitySpendRuleFromPaymentCard = (paymentCard?: SelectedPaymentCardProps) => {
  const filteredRules =
    paymentCard?.card.attachedVelocityRules?.edges?.filter(
      (velocityRule) => (velocityRule.node?.cumulativeRule?.__typename as string) === 'HNAmountLimitSpendRule'
    ) || [];

  return filteredRules.at(0)?.node ?? undefined;
};

export const getAttachedCVVSpendRuleFromPaymentCard = (paymentCard?: SelectedPaymentCardProps) => {
  const filteredRules =
    paymentCard?.card.attachedSpendRules?.edges?.filter(
      (spendRule) => (spendRule.node?.__typename as string) === 'HNCVVSpendRule'
    ) || [];

  return filteredRules.length > 0 ? (filteredRules.at(0)?.node as unknown as HNCVVSpendRule) : undefined;
};

export const getAttachedStreetAddressSpendRuleFromPaymentCard = (paymentCard?: SelectedPaymentCardProps) => {
  const filteredRules =
    paymentCard?.card.attachedSpendRules?.edges?.filter(
      (spendRule) => (spendRule.node?.__typename as string) === 'HNStreetAddressSpendRule'
    ) || [];

  return filteredRules.length > 0
    ? (filteredRules.at(0)?.node as unknown as HNStreetAddressSpendRule)
    : undefined;
};

export const getAttachedMerchantSpendRuleFromPaymentCard = (
  rules: MerchantSpendRule[],
  paymentCard?: SelectedPaymentCardProps
) =>
  paymentCard?.card.id
    ? rules.find((rule) => rule.paymentCards && rule.paymentCards.includes(paymentCard?.card.id))
    : undefined;
