import { EditOutlined as EditIcon, Error, Lock, LockOpen } from '@mui/icons-material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import {
  GetCardsCardholderPayload,
  GetCardsPayload,
  HighnoteTransactionStatus,
  HNPaymentCardClientTokenPermission,
  HNPaymentCardStatus,
} from 'API';
import useProfilePictureMap from 'app/hooks/useProfilePictureMap';
import useSidesheetById from 'app/hooks/useSidesheetById';
import CardTransactionsTable from 'app/pages/cards/card/CardTransactionsTable';
import PinSheet from 'app/pages/cards/card/PinSheet';
import ShippingStatus from 'app/pages/cards/card/ShippingStatus';
import { FormType } from 'app/pages/cards/cards/NewCardSheet/newCardUtils';
import { getHNBusinessAccountHolder } from 'app/pages/onboard/store';
import { getCardByHightnoteId } from 'app/pages/store/paymentCardsSlice';
import { selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import {
  generatePaymentCardClientToken,
  getHighnotePaymentCard,
  resetSpecificCardSlice,
  selectSpecificCardSlice,
} from 'app/pages/store/specificCardSlice';
import {
  getTransactions,
  resetTransactionsSlice,
  selectAllTransactions,
  selectTransactionsSlice,
} from 'app/pages/store/transactionsSlice';
import DatePicker from 'app/shared-components/layout/table/DatePicker';
import LoadMoreButton from 'app/shared-components/layout/table/LoadMoreButton';
import ModalActionsCard, { ModalType } from 'app/shared-components/ModalActionsCard/ModalActionsCard';
import SideSheetContent from 'app/shared-components/sidesheets/SideSheetContent';
import SideSheetHeader from 'app/shared-components/sidesheets/SideSheetHeader';
import { SidesheetRoutes, useSidesheet } from 'app/shared-components/sidesheets/SidesheetProvider';
import SpecificCard from 'app/shared-components/SpecificCard';
import BudgetProgressBar from 'app/shared-components/ui/BudgetProgress/BudgetProgressBar';
import Currency from 'app/shared-components/util/Currency';
import Loading from 'app/shared-components/util/Loading';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { selectUserProfile } from 'app/store/userProfileSlice';
import { ReactComponent as MasterCardLogo } from 'assets/master-card-logo.svg';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BillingAddress } from './BillingAddress';
import {
  CardDetails,
  CardUnactivatedMessage,
  CardImageContainer,
  CardControlsContainer,
  SecurityButton,
} from './style';
export interface CardSheetProps {
  onClose: (shouldActivate: boolean) => void;
  paymentCardId?: string;
  loadPaymentCards: () => Promise<void>;
}

function CardSheet() {
  const dispatch = useAppDispatch();

  const { openSidesheet } = useSidesheet();
  const sidesheet = useSidesheetById(SidesheetRoutes.Card);

  const params: CardSheetProps = sidesheet.params;

  const { selectedPaidolId } = useAppSelector(selectUserCompanies);

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [zipcode, setZipcode] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.lock);
  const [filterDate, setFilterDate] = useState<Date | null>(new Date());
  const [pinPanelOpen, setPinPanelOpen] = useState(false);
  const [selectedPaymentCard, setSelectedPaymentCard] = useState<GetCardsPayload>();
  const { integration } = useAppSelector(selectReviewOnboardSlice);
  const { cardProduct, formFactor, status, paymentCardDetails, cardUsers, balance, velocityRule } =
    useAppSelector(selectSpecificCardSlice);

  const transactions = useAppSelector(selectAllTransactions);
  const { profile } = useAppSelector(selectUserProfile);

  const spendInMonth = useMemo(() => {
    return transactions.reduce((acc, transaction) => {
      return transaction.status !== HighnoteTransactionStatus.REFUNDED
        ? acc + Number(transaction?.approvedAmount?.value ?? 0)
        : acc;
    }, 0);
  }, [transactions]);

  const highnotePaymentCard = paymentCardDetails?.highnotePaymentCard;

  const loadHighnotePaymentCard = useCallback(
    (paymentCardId: string, paidolId: string) =>
      dispatch(
        getHighnotePaymentCard({
          paymentCardId,
          paidolId,
          yearAndMonth: format(new Date(), 'yyyy-MM'),
        })
      )
        .unwrap()
        .then((response) => {
          const cardBelongsToCurrentUser = profile
            ? response.highnotePaymentCard?.paidolUsers?.items.find(
                (item) => item?.paidolUser?.user?.id === profile.id
              )
            : false;
          if (!response?.highnotePaymentCard?.hasPin && cardBelongsToCurrentUser) {
            return params?.onClose(true);
          }
        }),
    [dispatch, profile, params]
  );

  const loadTransactions = useCallback(
    (paymentCardId: string, paidolId: string) =>
      dispatch(
        getTransactions({
          paidolId,
          paymentCardId,
          filterDate: filterDate,
          shouldGetCardDetails: false,
        })
      ),
    [dispatch, filterDate]
  );

  const loadPaymentCardClientToken = useCallback(
    (paymentCardId: string) =>
      dispatch(
        generatePaymentCardClientToken({
          paymentCardId: paymentCardId,
          permissions: [HNPaymentCardClientTokenPermission.READ_RESTRICTED_DETAILS],
        })
      )
        .unwrap()
        .then((response) => setToken(response)),
    [dispatch]
  );

  const loadAccountBusinessHolder = useCallback(
    (paidolId: string) =>
      dispatch(getHNBusinessAccountHolder(paidolId))
        .unwrap()
        .then((response) => {
          setZipcode(response?.businessProfile?.billingAddress?.postalCode || '');
        }),
    [dispatch]
  );

  const loadPaymentCard = useCallback(
    (id: string, paidolId: string) =>
      dispatch(
        getCardByHightnoteId({
          id,
          paidolId,
          yearAndMonth: format(new Date(), 'yyyy-MM'),
        })
      )
        .unwrap()
        .then((response) => {
          setSelectedPaymentCard(response);
        }),

    [dispatch]
  );

  const initialLoad = useCallback(() => {
    if (params.paymentCardId && selectedPaidolId) {
      setLoading(true);

      const promises = [
        () => loadTransactions(params.paymentCardId || '', selectedPaidolId),
        () => loadPaymentCardClientToken(params.paymentCardId || ''),
        () => loadHighnotePaymentCard(params.paymentCardId || '', selectedPaidolId),
        () => loadAccountBusinessHolder(integration?.businessAccountHolderId || ''),
        () => loadPaymentCard(params.paymentCardId || '', selectedPaidolId),
      ];

      Promise.allSettled(promises.map((p) => p())).finally(() => {
        setLoading(false);
      });
    }
  }, [
    integration?.businessAccountHolderId,
    loadAccountBusinessHolder,
    loadHighnotePaymentCard,
    loadPaymentCard,
    loadPaymentCardClientToken,
    loadTransactions,
    params.paymentCardId,
    selectedPaidolId,
  ]);

  useEffect(() => {
    if (integration) initialLoad();
    return () => {
      dispatch(resetSpecificCardSlice());
      dispatch(resetTransactionsSlice());
    };
  }, [dispatch, integration, initialLoad]);

  const handleOpenModal = (type: ModalType) => {
    setModalType(type);
    setOpenModal(true);
  };

  const {
    loadingMore,
    nextToken,
    total,
    loading: loadingTransactions,
  } = useAppSelector(selectTransactionsSlice);

  const loadMore = useCallback(() => {
    if (params.paymentCardId) {
      dispatch(
        getTransactions({
          paidolId: selectedPaidolId,
          paymentCardId: params.paymentCardId,
          shouldGetCardDetails: false,
          filterDate: filterDate,
          nextToken,
        })
      );
    }
  }, [params, dispatch, selectedPaidolId, nextToken, filterDate]);

  const cardholders: GetCardsCardholderPayload[] = useMemo(
    () =>
      cardUsers?.map((cardUser) => {
        let fullCardholderName = cardUser.paidolUser.user?.first_name;
        if (fullCardholderName && cardUser.paidolUser.user?.last_name)
          fullCardholderName += ` ${cardUser.paidolUser.user?.last_name}`;

        const cardholder = {
          id: cardUser.paidolUser.user?.id ?? '',
          name: fullCardholderName,
          email: cardUser.paidolUser.user?.email ?? cardUser.paidolUser.email ?? '',
          picture: cardUser.paidolUser.user?.picture,
        };

        return {
          __typename: 'GetCardsCardholderPayload',
          ...cardholder,
        };
      }) || [],
    [cardUsers]
  );

  const { CardholderComponent } = useProfilePictureMap(cardholders);

  const { t } = useTranslation();

  const cardName = highnotePaymentCard?.name ?? cardProduct.name;
  const isCardActive = status === HNPaymentCardStatus.ACTIVE;
  const isCardCanceled = status === HNPaymentCardStatus.CLOSED;
  const isCardUnactivated = status === HNPaymentCardStatus.ACTIVATION_REQUIRED;
  const isCardEditable = isCardActive && !!cardholders.length;

  const cardBelongsToCurrentUser = profile
    ? highnotePaymentCard?.paidolUsers?.items.find((item) => item?.paidolUser?.user?.id === profile.id)
    : false;

  return (
    <>
      <SideSheetHeader
        title={`${cardName}${isCardCanceled ? ' - CANCELED' : ''}`}
        onClose={() => params?.onClose(false)}
        ButtonsComponent={
          isCardEditable && (
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => {
                openSidesheet(SidesheetRoutes.NewCard, {
                  selectedPaymentCard: selectedPaymentCard,
                  formType: FormType.UpdateCard,
                  loadPaymentCards: params.loadPaymentCards,
                  onUpdateCard: initialLoad,
                });
              }}
            >
              {t('editCard')}
            </Button>
          )
        }
      />

      <SideSheetContent>
        {loading || loadingTransactions ? (
          <Loading />
        ) : (
          <React.Fragment>
            <Grid
              container
              sx={{
                flexDirection: { sm: 'center', md: 'center', lg: 'flex-start' },
              }}
            >
              {/* Card Info display */}

              <CardDetails>
                {isCardUnactivated ? (
                  <CardUnactivatedMessage>
                    <Box sx={{ mx: { xs: 2, sm: 5 }, alignItems: 'center' }}>
                      <Typography variant="body1" fontSize="1.4rem" fontWeight="500">
                        {t('unactivatedCardMessage')}
                      </Typography>
                    </Box>
                    <Chip
                      style={{
                        color: 'white',
                        fontWeight: 500,
                        fontSize: 18,
                      }}
                      icon={<PowerSettingsNewIcon fontSize="medium" />}
                      label={t('unactivated')}
                      color="warning"
                    />
                  </CardUnactivatedMessage>
                ) : null}
                <CardImageContainer isCardUnactivated={isCardUnactivated}>
                  <SpecificCard
                    paymentCardId={params.paymentCardId ?? ''}
                    clientToken={token}
                    zipcode={zipcode}
                    name={cardName}
                    formFactor={formFactor}
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                  />
                  {/* Security buttons */}
                  <CardControlsContainer>
                    <Typography variant="body1" fontSize="1.8rem" fontWeight="500">
                      {t('security')}
                    </Typography>

                    {status === 'SUSPENDED' ? (
                      <SecurityButton
                        startIcon={<LockOpen />}
                        onClick={() => handleOpenModal(ModalType.unlock)}
                        disabled={isCardCanceled}
                      >
                        {t('unlockCard')}
                      </SecurityButton>
                    ) : (
                      <SecurityButton
                        startIcon={<Lock />}
                        onClick={() => handleOpenModal(ModalType.lock)}
                        disabled={isCardCanceled}
                      >
                        {t('lockCard')}
                      </SecurityButton>
                    )}
                    {cardBelongsToCurrentUser && (
                      <SecurityButton
                        startIcon={<Lock />}
                        onClick={() => setPinPanelOpen(true)}
                        disabled={isCardCanceled}
                      >
                        {t('resetPIN')}
                      </SecurityButton>
                    )}
                    <SecurityButton
                      startIcon={<Error />}
                      onClick={() => handleOpenModal(ModalType.cancel)}
                      disabled={isCardCanceled}
                    >
                      {t('cancelCard')}
                    </SecurityButton>
                  </CardControlsContainer>
                </CardImageContainer>
              </CardDetails>

              {/* Billing address */}
              <BillingAddress />

              {/* Card User Info */}
              <Box sx={{ pl: 1, pt: { xs: 1 } }}>
                {isCardCanceled ? (
                  <Typography textAlign="center" variant="h3" mb={2} color="error.main">
                    {t('canceled')}
                  </Typography>
                ) : null}
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    mb: 2,
                    mt: 1,
                  }}
                >
                  <MasterCardLogo />
                  <Typography variant="h4">{cardName}</Typography>
                </Box>
                <Typography variant="body2" fontWeight="normal">
                  {t('authorizedUser')}
                </Typography>
                {cardholders?.map((cardholder) => {
                  return (
                    <React.Fragment key={cardholder.id}>{CardholderComponent(cardholder)}</React.Fragment>
                  );
                })}
                <Box sx={{ display: 'flex', mt: 3 }}>
                  <Typography variant="body1" sx={{ display: 'flex', flexDirection: 'column' }}>
                    {t('group')} <strong>{highnotePaymentCard?.group?.name ?? t('noGroup')}</strong>
                  </Typography>
                </Box>
              </Box>
              <ShippingStatus paymentCardDetails={paymentCardDetails} />
            </Grid>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 5,
                mt: 8,
              }}
            >
              <Box sx={{ width: { xs: '100%', sm: 500 } }}>
                <Typography variant="display">
                  <strong>
                    <Currency
                      value={spendInMonth}
                      currency={balance?.currentBalance?.currencyCode ?? 'USD'}
                    />
                  </strong>
                </Typography>
                <BudgetProgressBar
                  sx={{
                    mt: 5,
                    mb: 3,
                  }}
                  spent={{ value: spendInMonth, currency: balance?.currentBalance?.currencyCode ?? 'USD' }}
                  budget={{
                    value: velocityRule?.maximumAmount?.value ?? 0,
                    currency: velocityRule?.maximumAmount?.currencyCode ?? 'USD',
                  }}
                />
                <Typography
                  variant="body1"
                  fontWeight="normal"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  {t('monthlySpendLimit')}
                  <strong>
                    {velocityRule && (
                      <Currency
                        value={velocityRule?.maximumAmount?.value ?? 0}
                        currency={velocityRule?.maximumAmount?.currencyCode ?? 'USD'}
                      />
                    )}
                    {!velocityRule && t('noLimit')}
                  </strong>
                </Typography>
              </Box>
            </Box>
            <DatePicker
              initialValue={filterDate}
              minDate={new Date(2023, 0)}
              maxDate={new Date()}
              onAccept={(newDate) => setFilterDate(newDate)}
            />
            <CardTransactionsTable transactions={transactions} loading={loading} cardUsers={cardUsers} />
            <LoadMoreButton
              loadMore={loadMore}
              loading={loadingMore}
              nextToken={total > transactions.length ? nextToken : undefined}
            />
            <ModalActionsCard
              open={openModal}
              onClose={() => setOpenModal(false)}
              type={modalType}
              paymentCardId={params.paymentCardId ?? ''}
              cardName={cardName}
              paymentCardUsers={cardUsers}
            />
          </React.Fragment>
        )}
      </SideSheetContent>

      {pinPanelOpen && (
        <PinSheet
          paymentCardId={params.paymentCardId ?? ''}
          open={pinPanelOpen}
          card={highnotePaymentCard}
          cardProduct={cardProduct}
          onClose={() => {
            setPinPanelOpen(false);
          }}
        />
      )}
    </>
  );
}

export default CardSheet;
