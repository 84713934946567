import { getPageName } from 'util/helpers';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SidesheetRoutes, useSidesheet } from 'app/shared-components/sidesheets/SidesheetProvider';
import { Box, Button, SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/store';
import { CriteriaColumnItem, FilterChangeProps } from './CriteriaColumnItem';
import {
  applyColumnFilters,
  ColumnFilterMap,
  clearColumnFilters,
  selectSearchAndFilter,
} from 'app/store/searchAndFilterSlice';

export type TransactionAdvancedSearchColumn = {
  name: string;
  type: 'text' | 'number' | 'date';
};

export type TransactionAdvancedSearchSidesheetParams = {
  pageName: string;
};

function TransactionAdvancedSearchSidesheet() {
  const dispatch = useAppDispatch();
  const pageName = getPageName(window.location.pathname);
  const tableFilters = useAppSelector(selectSearchAndFilter)[pageName];
  const [localColumnFilters, setLocalColumnFilters] = useState<ColumnFilterMap>(tableFilters?.columnFilters);
  const { closeSidesheet } = useSidesheet();
  const { t } = useTranslation();

  useEffect(() => {
    const filtersToClear = structuredClone(localColumnFilters);

    Object.keys(filtersToClear).forEach((key) => {
      if (filtersToClear[key].selectedValues?.length === 0) {
        filtersToClear[key].active = false;
      }
    });

    setLocalColumnFilters(filtersToClear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCriteriaRuleChange = (event: SelectChangeEvent, key: string) => {
    setLocalColumnFilters((previousValue) => {
      const changedFilterTable = { ...localColumnFilters[key] };
      changedFilterTable.rule = event.target.value;
      return {
        ...previousValue,
        [key]: changedFilterTable,
      };
    });
  };

  const handleSelectedValuesChange = ({
    key,
    selectedOptions,
    minNumber,
    maxNumber,
    active,
  }: FilterChangeProps) => {
    setLocalColumnFilters((previousValue) => {
      const changedFilterTable = { ...localColumnFilters[key] };
      if (selectedOptions) {
        changedFilterTable.selectedValues = selectedOptions;
      }
      changedFilterTable.minNumber = minNumber;
      changedFilterTable.maxNumber = maxNumber;
      changedFilterTable.active = active;
      return {
        ...previousValue,
        [key]: changedFilterTable,
      };
    });
  };

  const handleApplyColumnFilters = () => {
    dispatch(applyColumnFilters({ pageName, columnFilters: localColumnFilters }));
    closeSidesheet(SidesheetRoutes.TransactionsAdvancedSearch);
  };

  const handleClearColumnFilters = () => {
    const filtersToClear = structuredClone(localColumnFilters);
    Object.keys(filtersToClear).forEach((key) => {
      filtersToClear[key].selectedValues = [];
      filtersToClear[key].minNumber = undefined;
      filtersToClear[key].maxNumber = undefined;
      filtersToClear[key].active = false;
      filtersToClear[key].rule = filtersToClear[key].type === 'number' ? 'range' : 'noExcept';
    });
    setLocalColumnFilters(filtersToClear);
    dispatch(clearColumnFilters({ pageName }));
  };

  return (
    <Box className="flex flex-col justify-between h-full px-12">
      <Box>
        {localColumnFilters &&
          Object.values(localColumnFilters).map((filter, i) => (
            <CriteriaColumnItem
              key={filter.key}
              filter={filter}
              isLast={i === Object.values(localColumnFilters).length - 1}
              onFilterChange={handleSelectedValuesChange}
              onRuleChange={handleCriteriaRuleChange}
            />
          ))}
      </Box>

      <Box className="flex self-end gap-16 py-8">
        {/* <Button size="small">Save search</Button> */}
        <Button variant="outlined" size="small" onClick={handleClearColumnFilters}>
          {t('reset')}
        </Button>
        <Button variant="contained" size="small" onClick={handleApplyColumnFilters}>
          {t('showResults')}
        </Button>
      </Box>
    </Box>
  );
}

export default TransactionAdvancedSearchSidesheet;
