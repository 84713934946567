import type { CardGroup, PaidolUser } from 'API';

export const paidolUserByPaidolId = /* GraphQL */ `
  query PaidolUserByPaidolId(
    $paidol_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPaidolUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paidolUserByPaidolId(
      paidol_id: $paidol_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        __typename
        id
        paidol_id
        user_id
        user {
          id
          createdAt
          updatedAt
          first_name
          last_name
          picture
          email
        }
        email
        position
        phone
        roles
        is_test_fixture
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const paidolUserByPaidolIdNoLimit = /* GraphQL */ `
  query PaidolUserByPaidolIdNoLimit($paidol_id: ID!, $roles: ModelStringKeyConditionInput) {
    paidolUserByPaidolId(paidol_id: $paidol_id, roles: $roles) {
      items {
        __typename
        id
        paidol_id
        user_id
        user {
          id
          createdAt
          updatedAt
          first_name
          last_name
          picture
          email
        }
        email
        position
        phone
        roles
        is_test_fixture
        createdAt
        updatedAt
      }
    }
  }
`;

export type GetGroupPaidolUsersQuery = {
  listCardGroupsByPaidolId?: {
    __typename: 'ModelCardGroupConnection';
    items: Array<CardGroup>;
    nextToken?: string | null;
  } | null;
};

export const GetGroupPaidolUsers = /* GraphQL */ `
  query GetGroupPaidolUsers($paidolId: ID!, $nextToken: String, $limit: Int = 100) {
    listCardGroupsByPaidolId(paidolId: $paidolId, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        spendGroupId
        authPaidolId
        isMonthlyBudgetUnlimited
        paymentCards(limit: $limit) {
          items {
            paymentCardId
            paidolUsers {
              items {
                paidolUser {
                  user_id
                  id
                  email
                  roles
                  user {
                    id
                    first_name
                    last_name
                    picture
                    email
                  }
                }
              }
            }
          }
        }
        groupAdmins {
          items {
            paidolUser {
              user_id
              id
              email
              roles
              user {
                id
                first_name
                last_name
                picture
                email
              }
            }
          }
        }
        monthlyBudget {
          currency
          value
        }
        monthlyBudgetSpend(sortDirection: DESC) {
          items {
            amount
            yearAndMonth
          }
        }
      }
    }
  }
`;

export type PaidolUserByPaidolIdQuery = {
  paidolUserByPaidolId?: {
    __typename: 'ModelPaidolUserConnection';
    items: Array<PaidolUser | null>;
    nextToken?: string | null;
  } | null;
};
